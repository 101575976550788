import axios from 'axios'
import getEnv from '@/utils/env'

export default class Config {

    #config

    // tries to obtain configuration from the backend
    // and then calls the callback
    constructor(callback) {
        var url = getEnv('VUE_APP_MIDDLEWARE_URL')
        url += process.env.VUE_APP_CONFIG_ENDPOINT
        
        axios.get(url)
        .then(result => {
            this.#config = result.data
            callback()
        })
        .catch(err => {
            console.log('BACKEND UNREACHABLE ' + err)
        })
    }

    current = function() {
        return this.#config
    }
}