<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="[{ 'error' : error }, { 'with-label' : label }]">
        <label :for="inputid" :class="{ 'with-content' : inputvalue !== null }" class="input-label" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''" v-if="label">{{ this.label }} {{ required ? '*' : '' }}</label>
        
        <select class="input" v-if="!display" :id="inputid" :required="required" :placeholder="placeholder" :disabled="disabled" v-model="inputvalue" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">
            <option :value="defaultitem.displayid" :selected="!selected || selected == ''" v-if="defaultoption" >{{ defaultitem.displayname }}</option>
            <option v-for="option in optionlist" :selected="selected == option.displayid ? 'selected' : ''" :key="option.displayid" :value="option.displayid">{{ option.displayname }}</option>
        </select>
        <label v-else>{{ value }}</label>
        
        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownInput',
    props: {
        value: Number,
        defaultoptionid: {
            type: Number,
            default: -1
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        selected: {
            type: Number,
            default: null
        },
        label: String,
        required: Boolean,
        disabled: Boolean,
        display: Boolean,
        isloading: Boolean,
        defaultoption: Boolean,
        optionlist: Array, // [] of "{displayid: 0, displayname: 'name'}"
        error: Boolean,
        errortext: String,
        backgroundcolor: String
    },
    computed: {
        defaultitem() {
            return { displayid: this.defaultoptionid, displayname: '--- ' + this.$t('general.pleasechoose') + ' ---'}
        },
        inputid: function() {
            return `input-${this._rng(5)}`
        },
        inputvalue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
}

.input-wrapper.with-label {
    padding-top: 20px;
}

.input-wrapper > .input-label {
    position: absolute;
    font-size: 1em;
    left: 10px;
    background: #fff;
    top: 10px;
    padding: 0px 5px;
    transition: .1s all;
    cursor: text;
}

.input-wrapper > .input-label.with-content {
    top: 0px;
    left: 0px;
}

select {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 1.4em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid #064A6C;
    border-radius: 5px;
}

select::placeholder {
    font-size: 1em;
}

/* .input-wrapper.with-label > select {
    padding-top: 5px;
    height: 35px;
} */

.textinput-container > label {
    color: #fff;
}

.input-label {
    font-size: 1.2em;
    color: #2c2e2f;
}

.error-container {
    margin-top: 3px;
    padding-left: 10px;
    color: red;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
}

.input-wrapper.error > input {
    border: 1px solid red;
}
</style>