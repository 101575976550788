<template>
    <PopUp v-if="open" :isopen="open" @close="hideTicketPopup" :title="title" :buttonless="true">
        <div class="qr-container">
            <img :src="getQrCodeImage(ticket.barcode)" height="225px"/>
        </div>
        <div class="info-container">
            <div class="title">
                <label>{{ ticket.name }}</label>
            </div>
            <div class="validity">
                <label class="label">{{ $t('landing.lastticket.validuntil').toUpperCase() }}</label>
                <label class="value" :class="{ 'invalid' : this.ticket.validuntil < new Date()}">{{ _getDateTimeString(this.ticket.validuntil, true) }}</label>
                <label class="label">{{ $t('landing.lastticket.price').toUpperCase() }}</label>
                <label class="value">{{ _currencyValueToFloat(this.ticket.price) }} €</label>
            </div>
            <div class="info">
                <div>
                    <label>{{ $t('landing.lastticket.ticketid').toUpperCase() }}</label>
                    <label>{{ $t('landing.lastticket.dateofpurchase').toUpperCase() }}</label>
                </div>
                <div>
                    <label>{{ ticket.id}}</label>
                    <label>{{ _getDateTimeString(this.ticket.dateofpurchase) }}</label>
                </div>
            </div>
            <div class="storno">
                <button id="btn-cancel-ticket" @click="showConfirmation">{{ $t('landing.lastticket.cancel-ticket') }}</button>
            </div>
        </div>

        <PopUp v-if="confirmationopen" :isopen="confirmationopen" @close="hideConfirmation" :title="$t('general.are-you-sure')" :submitlabel="$t('general.cancel-ticket')" :cancellabel="$t('general.no')">
            <label>{{ $t('landing.lastticket.cancel-confirmation') }}</label>
        </PopUp>
    </PopUp>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import getEnv from '@/utils/env'

import PopUp from './elements/Popup.vue'

export default {
    name: 'LastTicketDepiction',
    props: {
        ticket: Object,
        open: Boolean,
        title: {
            type: String,
            default: function () { return this.$t('landing.lastticket.header') }
        }
    },
    data() {
        return {
            confirmationopen: false
        }
    },
    methods: {
        hideTicketPopup: function() {
            this.$emit('close')
        },
        getQrCodeImage: function(input) {
            let binary = new Array()

            for (let i = 0; i < input.length / 2; i++) {
                let h = input.substr(i * 2, 2)
                binary[i] = parseInt(h, 16)
            }

            let byteArray = new Uint8Array(binary)
            let src = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }))
            return src
        },
        showConfirmation: function() {
            this.confirmationopen = true
        },
        hideConfirmation: function(submit) {
            if (submit) {
                this.cancelTicket()
            }
            this.confirmationopen = false
        },
        cancelTicket: function() {
            let url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/account/' + this.$keycloak.tokenParsed.sub
            url += '/tickets/' + this.ticket.id

            axios.delete(url)
            .then(() => {
                Vue.$toast.info(this.$t('landing.lastticket.cancel-success'))
                this.hideTicketPopup()
            })
            .catch(() => {
                Vue.$toast.error(this.$t('landing.lastticket.cancel-failed'))
            })
        }
    },
    components: {
        PopUp
    }
}
</script>

<style scoped>
.qr-container {
    background: #fff;
    border-radius: 5px;
    padding: 30px;
    margin: auto;
    text-align: center;
    animation: pulse 3s infinite;
}

.qr-container > img {
    border-radius: 5px;
    border: 2px solid #0B81BC;
}

.info-container {
    margin-top: 20px;
    font-size: 1.3em;
    text-align: center;
}

.info-container > .title {
    padding: 5px 0;
    font-size: 1.5em;
}

.info-container > .validity {
    border-top: 2px solid #064A6C;
    border-bottom: 2px solid #064A6C;
    padding: 5px 0;
}

.info-container > .validity > label {
    display: block;
}

.info-container > .validity > .label {
    margin: 5px 0;
    margin-top: 10px;
}

.info-container > .validity > .value {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1.5em;
}
.info-container > .validity > .value.invalid {
    color: red;
}

.info-container > .validity > label:nth-child(2) {
    margin-bottom: 20px;
}

.info-container > .info {
    display: flex;
}

.info-container > .info > div {
    flex: 5;
    margin-top: 10px;
    padding: 2px 10px;
    font-size: 0.7em;
}

.info-container > .info > div:nth-child(1) {
    text-align: right;
}

.info-container > .info > div:nth-child(2) {
    text-align: left;
}

.info-container > .info > div > label {
    display: block;
}

.info-container > .storno {
    margin-top: 10px;
}

.info-container > .storno > button {
    color: red;
    text-transform: unset;
}

.info-container > .storno > button:hover {
    background: #ff000022;
}

@keyframes pulse {
    0% {
        background-color: #fff;
    }

    50% {
        background-color: #064A6C;
    }

    100% {
        background-color: #fff;
    }
}
</style>