import Vue from 'vue'
import Router from 'vue-router'

import NotFoundPage from '../pages/NotFoundPage'
import LandingPage from '../pages/LandingPage'
import CheckoutPage from '../pages/CheckoutPage'
import ProfileSwitcherPage from '../pages/ProfileSwitcherPage'

// register navigation
Vue.use(Router)

const options = {
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'root',
            component: LandingPage
        },
        {
            path: '/checkout',
            component: CheckoutPage
        },
        {
            path: '/userprofile',
            component: ProfileSwitcherPage,
            meta: {
                nofooter: true
            }
        },

        // ----- DEFAULT ROUTE MUST BE LAST! -----
        {
            path: '*',
            name: 'notfound',
            component: NotFoundPage,
            // always redirect to landing page
            beforeEnter: (to, from, next) => {
                next('/')
            }
        }
    ],
}

export default new Router(options);
