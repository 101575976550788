<template>
    <div class="depiction-container">
        <div class="path">
            <label>{{ this.starttime }}</label>
            <label>{{ this.endtime }}</label>
        </div>
        <div class="type">
            <svg height="80" width="10">
                <line x1="5" y1="9" x2="5" y2="71" style="stroke-width: 2" :stroke-dasharray="getDashArray()" :class="_getIconParam(this.leg.type)"/>
                <circle cx="5" cy="9" r="5" fill="black"/>
                <circle cx="5" cy="71" r="5" fill="black"/>
            </svg>
            <v-icon :name="_getIconParam(this.leg.type)" />
        </div>
        <div class="description">
            <div class="start">
                <label>{{ this.leg.start }}</label>
            </div>

            <div class="mid">
                <label>{{ this.leg.duration }} min</label>
                <label v-if="this.leg.line && this.leg.destinationref">{{ `${$t('landing.details.line')} ${this.leg.line}, ${$t('landing.details.direction')} ${this.leg.destinationref}` }}</label>
            </div>

            <div class="end">
                <label>{{ this.leg.end }}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TripPartDepiction',
    props: {
        leg: Object
    },
    methods: {
        getDashArray: function() {
            if (this.leg.type === 'WALK') {
                return '5,2'
            }
            return '0,0'
        }
    },
    computed: {
        starttime: function() {
            let date = new Date(this.leg.starttime)
            return `${this._leadingZero(date.getHours())}:${this._leadingZero(date.getMinutes())}`
        },
        endtime: function() {
            let date = new Date(this.leg.endtime)
            return `${this._leadingZero(date.getHours())}:${this._leadingZero(date.getMinutes())}`
        }
    }
}
</script>

<style scoped>
.depiction-container {
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
}

.depiction-container > div {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.depiction-container > .path {
    flex: 1;
    text-align: right;
    padding-right: 5px;
}

.depiction-container > .description > .start,
.depiction-container > .description > .end,
.depiction-container > .path > label {
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.depiction-container > .description > .mid {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-weight: bold;
    color: #666;
    font-size: .9em;
}

.depiction-container > .description > .mid > label:nth-child(2) {
    margin-top: 5px;
}

.depiction-container > .type {
    flex: 1;
    width: 100%;
    color: #666;
    display: flex;
    flex-direction: row;
    padding: 0 10px 0 5px;
    box-sizing: border-box;
}

.depiction-container > .type > svg {
    height: 100%;
    margin: auto;
}

.depiction-container > .type > svg:nth-of-type(1) {
    flex: 6;
}

.depiction-container > .type > svg:nth-of-type(2) {
    flex: 4;
}

.depiction-container > .description {
    flex: 8;
    padding-left: 5px;
    text-align: left;
    overflow: hidden;
}
</style>