<template>
    <div class="searchbox-container">
        <div class="searchbox-header">
            <LanguageChanger class="changer"/>
        </div>

        <div class="searchinput-container">
            <!-- <label>{{ $t('landing.search.start') }}</label> -->
            <v-icon name="home" class="search-icon" scale="1.5" :title="$t('landing.search.placeholder.from')"/>
            <AutoCompleteSearch inputid="input-from" @selection="fromSelected" :placeholder="$t('landing.search.placeholder.from')" v-model="inputfrom.displayname" @input="searchFromLocation" :disabled="isloading" :isloading="fromloading" :resultlist="fromlocationlist"/>
        </div>
        <div class="searchinput-container">
            <!-- <label>{{ $t('landing.search.end') }}</label> -->
            <v-icon name="map-marker-alt" class="search-icon" scale="1.5" :title="$t('landing.search.placeholder.to')"/>
            <AutoCompleteSearch inputid="input-to" @selection="toSelected" :placeholder="$t('landing.search.placeholder.to')" v-model="inputto.displayname" @input="searchToLocation" :disabled="isloading" :isloading="toloading" :resultlist="tolocationlist"/>
        </div>
        <div class="searchinput-container date">
            <label @click="toggleTimeFrom" class="toggle">
                <!-- <span :class="{ 'inactive' : !this.timefrom }">{{ $t('landing.search.from') }}</span> -->
                <v-icon name="plane-departure" :class="{ 'inactive' : !this.timefrom }" scale="1.5" :title="$t('landing.search.from')"/>
                <br />
                <!-- <span :class="{ 'inactive' : this.timefrom }">{{ $t('landing.search.to') }}</span> -->
                <v-icon name="plane-arrival" :class="{ 'inactive' : this.timefrom }" scale="1.5" :title="$t('landing.search.to')"/>
            </label>
            <DateInput displaytype="datetime" v-model="departuretime" :disabled="isloading" :time="true" />
        </div>
        <div class="search-button-container">
            <input id="btn-search" type="button" :value="$t('landing.search.buttons.search')" @click="getResult" :disabled="isloading || !inputfrom.data || !inputto.data || !departuretime"/>
            <div id="btn-search-reset" class="reset" v-show="!isloading && inputfrom.data && inputto.data && departuretime" @click="resetSearch">
                <v-icon name="times" />
            </div>
        </div>
    </div>
</template>

<script>
import AutoCompleteSearch from '../systems/AutoCompleteSearch.vue'
import LanguageChanger from '../systems/elements/LanguageChanger.vue'
import DateInput from './elements/DateInputSearch.vue'

import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import getEnv from '@/utils/env'

import 'vue-awesome/icons/times'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/map-marker-alt'
import 'vue-awesome/icons/plane-departure'
import 'vue-awesome/icons/plane-arrival'

export default {
    name: 'SearchBox',
    data() {
        return {
            inputfrom: { displayname: '' },
            inputto: { displayname: '' },
            isloading: false,
            issearching: false,
            fromlocationlist: [],
            tolocationlist: [],
            timefrom: true,
            departuretime: new Date(),
            fromloading: false,
            toloading: false
        }
    },
    methods: {
        resetSearch: function() {
            this.inputfrom = { displayname: ''}
            this.inputto = { displayname: '' }
            this.fromlocationlist = []
            this.tolocationlist = []
            this.departuretime = new Date()
            this.$emit('reset')
        },
        toggleTimeFrom: function() {
            this.timefrom ^= true
        },
        getResult: function() {
            this.isloading = true
            // reset result view
            this.$emit('searchresult')

            let types = ['TRANSIT', 'DRIVING', 'CYCLING', 'WALKING', 'ONDEMAND']
            let promises = []

            types.forEach(type => {
                let url = `${getEnv('VUE_APP_MIDDLEWARE_URL')}${process.env.VUE_APP_ROUTING_TRIP_ENDPOINT}/${type}`
                url += `?fromname=${this.inputfrom.data.name}&fromlat=${this.inputfrom.data.position.latitude}&fromlon=${this.inputfrom.data.position.longitude}`
                url += `&toname=${this.inputto.data.name}&tolat=${this.inputto.data.position.latitude}&tolon=${this.inputto.data.position.longitude}`

                if (this.timefrom) {
                    url += `&fromtime=${this.departuretime}`
                } else {
                    url += `&totime=${this.departuretime}`
                }

                let request = axios.get(url)
                .then(response => {
                    if(response.status === 200) {
                        // emit result so it can be used in the resultbox
                        this.$emit('searchresult', response.data)
                    } else {
                        // if the backend doesnt answer the mw (for whatever reason)
                        // we get a 204 "no content"
                        this.$emit('searchresult', { type: type, data: null})
                    }
                    this.$emit('tolocationname', this.inputto.data.name)
                })
                .catch(() => {
                    this.$emit('searchresult', { type: type, data: null})
                })

                promises.push(request)
            })

            Promise.allSettled(promises)
            .then(() => {
                this.isloading = false
            })
        },
        searchFromLocation: _.debounce(function() {
            if (this.inputfrom.displayname.trim().length === 0) {
                this.inputfrom = {}
            }

            if (this.inputfrom.displayname.length <= 3) {
                return
            }

            // reset additional data
            this.inputfrom = {
                displayname: this.inputfrom.displayname
            }

            this.fromloading = true

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_ROUTING_LOCATION_ENDPOINT
            url += '?name=' + this.inputfrom.displayname

            axios.get(url)
            .then(response => {
                this.fromlocationlist = response.data.map(entry => {
                    return {
                        displayid: entry.position.longitude + '' + entry.position.latitude,
                        displayname: entry.name,
                        data: entry
                    }
                })

                this.fromloading = false
            })
            .catch(err => {
                this.fromloading = false
                Vue.$toast.error(err)
            })
        }, 400),
        fromSelected: function(event) {
            this.fromlocationlist = []
            this.inputfrom = event
        },
        searchToLocation: _.debounce(function() {
            if (this.inputto.displayname.trim().length === 0) {
                this.inputto = {}
            }

            if (this.inputto.displayname.length <= 3) {
                return
            }

            // reset additional data
            this.inputto = {
                displayname: this.inputto.displayname
            }

            this.toloading = true

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_ROUTING_LOCATION_ENDPOINT
            url += '?name=' + this.inputto.displayname

            axios.get(url)
            .then(response => {
                this.tolocationlist = response.data.map(entry => {
                    return {
                        displayid: entry.position.longitude + '' + entry.position.latitude,
                        displayname: entry.name,
                        data: entry
                    }
                })

                this.toloading = false
            })
            .catch(err => {
                this.toloading = false
                Vue.$toast.error(err)
            })
        }, 400),
        toSelected: function(event) {
            this.tolocationlist = []
            this.inputto = event
        }
    },
    watch: {
        inputfrom: function(value) {
            this.$emit('fromselected', value.data)
        },
        inputto: function(value) {
            this.$emit('toselected', value.data)
        }
    },
    components: {
        AutoCompleteSearch,
        DateInput,
        LanguageChanger,
    }
}
</script>

<style scoped>
.searchbox-container {
    width: 100%;
    height: fit-content;
    background: #0069B4;
    /* background: #064A6C; */
    padding: 10px 15px;
    box-sizing: border-box;
}

.searchbox-container > .searchbox-header {
    position: relative;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
}

.searchbox-container > .searchinput-container {
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    text-align: right;
    align-items: center;
    line-height: 15px;
}

.searchbox-container > .searchinput-container >>> input {
    color: #fff;
    background-color: #0069B4;
    border-radius: 0px;
    padding-right: 20px;
    border: none;
    border-bottom: 1px solid white;
}

.searchbox-container > .searchinput-container.date {
    padding-left: 40px;
}

.searchbox-container > .searchinput-container > .toggle {
    display: flex;
    color: #fff;
    padding-top: 10px;
}

.searchbox-container > .searchinput-container > .toggle > span {
    font-size: 0.8em;
}

.searchbox-container > .searchinput-container > .toggle > svg {
    color: #fff;
    font-size: 1.3em;
    min-width: 25px;
    margin-right: 10px;
}

.searchbox-container > .searchinput-container > .toggle:hover {
    cursor: pointer;
}

.searchbox-container > .searchinput-container > .toggle > .inactive {
    color: #064A6C;
}

.searchbox-container > .searchinput-container > label {
    color: #fff;
    font-size: 1.3em;
    min-width: 65px;
    margin-right: 10px;
}

.searchbox-container > .searchinput-container > .search-icon {
    color: #fff;
    font-size: 1.3em;
    min-width: 30px;
    margin-right: 10px;
}

.searchbox-container > .search-button-container {
    width: 100%;
    text-align: right;
    padding-left: 12px;
}

.searchbox-container > .search-button-container > .reset {
    float: right;
    color: #fff;
    line-height: 30px;
    height: 30px;
    width: 30px;
    box-sizing: border-box;
    text-align: center;
    padding-top: 2px;
}

.searchbox-container > .search-button-container > .reset:hover {
    cursor: pointer;
    background-color: #ffffff22;
    border-radius: 50%;
}

.searchbox-container > .search-button-container > input {
    float: right;
}

@media(max-width: 650px) {
    .searchbox-container > .searchinput-container {
        margin: 10px 30px 15px 10px;
    }

    .searchbox-container > .search-button-container {
        padding: 0px 30px;
        padding-right: calc(30px - 12px);
        box-sizing: border-box;
    }
}
</style>