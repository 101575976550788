<template>
    <div class="search-container" :class="[{ 'extended' : true /* hasresults */ }, { 'closed' : !this.open}]">
        <!-- <div class="toggle-container" @click="toggleSidebar">
            <v-icon v-if="open" name="chevron-left" />
            <v-icon v-else name="chevron-right" />
        </div> -->
        <SearchBox class="upper" :class="{ 'closed' : !this.open }" @tolocationname="lastLocation" @reset="resetSearch" @searchresult="handleSearchResult" @fromselected="fromSelected" @toselected="toSelected" />
        
        <ResultBox v-show="this.open" class="lower" :class="{ 'closed' : !this.open }" @detailsClicked="showDetails" :results="results" v-if="results" :tolocation="tolocation" @tripselected="tripSelected" @ticketsClicked="showTickets" />
        <div class="details-container-wrapper">
            <div class="details-container" :class="{ 'showdetails' : this.showdetails && this.open }">
                <TripDetailsBox @back="hideDetails" :trip="trip" @showtickets="showTickets"/>
            </div>
        </div>
        <div class="tickets-container-wrapper">
            <div class="tickets-container" :class="{ 'showdetails' : this.showtickets && this.open }" v-if="trip.hasfares">
                <TicketBox @back="hideTickets" :trip="trip"/>
            </div>
        </div>

        <div class="ticket-button-container" :class="{ 'hidden' : this.showcatalog }">
            <input type="button" class="ticket-button" :value="$t('landing.search.buttons.tickets')" @click="openCatalog"/>
        </div>

        <TicketCatalog class="catalog" :class="{ 'closed' : !this.showcatalog }" @close="closeCatalog"/>

        <div class="background-container">
            <img src="@/assets/img/background.png" id="background" v-show="!this.results && !this.showcatalog"/>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/chevron-right'
import 'vue-awesome/icons/chevron-left'
import 'vue-awesome/icons/ticket-alt'

import SearchBox from './systems/SearchBox.vue'
import ResultBox from './systems/ResultBox.vue'
import TripDetailsBox from './systems/TripDetailsBox.vue'
import TicketBox from './systems/TicketBox.vue'
import TicketCatalog from './systems/TicketCatalog.vue'

export default {
    name: 'RoutingSearch',
    data() {
        return {
            results: null,
            tolocation: '',
            open: true,
            showdetails: false,
            showtickets: false,
            showcatalog: false,
            trip: { zeitmeilen: 0, interchanges: 0, legs: [] }
        }
    },
    mounted() {
        // this.showcatalog = true;
    },
    methods: {
        openCatalog: function() {
            this.showcatalog = true
            this.resetSearch()
        },
        closeCatalog: function() {
            this.showcatalog = false
        },
        showTickets: function() {
            this.showtickets = true
        },
        hideTickets: function() {
            this.showtickets = false
        },
        lastLocation: function(event) {
            this.tolocation = event
        },
        handleSearchResult: function(event) {
            if (event && this.results) {
                this.results.push(event)
            } else {
                this.showcatalog = false
                this.results = []
                this.hideDetails()
                this.hideTickets()
            }
        },
        resetSearch: function() {
            this.results = null
            this.hideDetails()
            this.hideTickets()
            this.$emit('reset')
        },
        fromSelected: function(event) {
            this.$emit('fromselected', event)
        },
        toSelected: function(event) {
            this.$emit('toselected', event)
        },
        tripSelected: function(event) {
            this.trip = event
            this.$emit('tripselected', event)
        },
        toggleSidebar: function() {
            this.open ^= true
        },
        showDetails: function() {
            this.showdetails = true
        },
        hideDetails: function() {
            this.showdetails = false
        }
    },
    computed: {
        hasresults: function() {
            return this.results && this.results.length > 0
        }
    },
    components: {
        SearchBox,
        ResultBox,
        TripDetailsBox,
        TicketBox,
        TicketCatalog,
    }
}
</script>

<style scoped>
.search-container {
    background-color: #064A6C;
    height: fit-content;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 400px;
    padding-bottom: 50px;
    box-shadow: 0px 1px 10px rgb(100, 100, 150, 0.7);
    overflow: hidden;
}

.search-container > .ticket-button-container {
    position: relative;
    height: 50px;
    line-height: 50px;
    background-color: #064A6C;
    width: 100%;
    transition: .3s;
}

.search-container > .ticket-button-container.hidden {
    /* margin-left: 400px; */
    height: 0px;
    overflow: hidden;
    /* height: 0px; */
}

.background-container {
    top: 300px;
    position: absolute;
    overflow: hidden;
    width: 400px;
}

.background-container > #background {
    position: relative;
    left: -480px;
    opacity: 0.1;
    height: 710px;
    width: auto;
    z-index: 1;
    filter: blur(4px);
}

.search-container.closed {
    width: 0;
}

.search-container > .toggle-container {
    position: absolute;
    height: 50px;
    width: 20px;
    left: 400px;
    top: 150px;
    background: #fff;
    box-shadow: 0px 1px 10px rgb(100, 100, 150, 0.7);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #0D93D4;
    transition: .3s;
    z-index: -1;
}

.search-container.closed > .toggle-container {
    width: 40px;
    left: 0;
}

.search-container.closed > .toggle-container > svg {
    width: 30%;
}

.search-container > .toggle-container:hover {
    cursor: pointer;
}

.search-container > .toggle-container > svg {
    height: 100%;
    width: 60%;
}

.search-container.extended {
    height: 100%;
}

.search-container > .upper {
    height: 250px;
}

.search-container > .tickets-container-wrapper,
.search-container > .details-container-wrapper {
    position: absolute;
    top: 250px;
    width: 0;
}

.search-container > .tickets-container-wrapper > .tickets-container,
.search-container > .details-container-wrapper > .details-container {
    position: relative;
    width: 0px;
    height: 100%;
    overflow: hidden;
    transition: .3s;
    background: #fff;
}

.search-container > .tickets-container-wrapper > .tickets-container.showdetails,
.search-container > .details-container-wrapper > .details-container.showdetails {
    width: 400px;
    /* width: 100%; */
}

.search-container > .tickets-container-wrapper > .tickets-container > div,
.search-container > .details-container-wrapper > .details-container > div {
    position: absolute;
    /* right: -400px; */
    left: -400px;
    transition: .3s;
}

.search-container > .tickets-container-wrapper > .tickets-container.showdetails > div,
.search-container > .details-container-wrapper > .details-container.showdetails > div {
    /* right: 0; */
    left: 0px;
}

.search-container > .lower {
    height: calc(100% - 300px);
}

.search-container > .tickets-container-wrapper,
.search-container > .details-container-wrapper {
    height: calc(100% - 250px);
}

.search-container > .upper.closed,
.search-container > .lower.closed {
    overflow: hidden;
    padding: 10px 0px;
}

.search-container > .catalog {
    transition: .3s;
}

.search-container > .catalog.closed {
    overflow: hidden;
    height: 0px;
}

@media(max-width: 650px) {
    .search-container {
        width: 100%;
        /* margin-top: 50px; */
    }

    .background-container {
        width: 100%;
    }

    .search-container > .toggle-container {
        left: 350px;
    }

    .search-container > .tickets-container-wrapper > .tickets-container.showdetails,
    .search-container > .details-container-wrapper > .details-container.showdetails {
        /* width: 350px; */
        width: 100vw;
    }

    /* .search-container > .tickets-container-wrapper,
    .search-container > .details-container-wrapper,
    .search-container > .lower {
        height: calc(100% - 250px);
    } */
}
</style>