<template>
    <div class="selection-container">
        <div v-for="item in radioitems" 
            :key="item.displayid"
            class="item" 
            :class="[{ 'active' : item.displayid === inputvalue }, { 'first' : item.displayid === radioitems[0].displayid }, { 'last' : item.displayid === radioitems[radioitems.length -1].displayid }]" >
            <input type="radio" :name="name" :id="item.displayid" :value="item.displayid" v-model="inputvalue">
            <label :for="item.displayid">
                <span>{{ item.displayname }}</span>
            </label>
            <v-icon name="check" v-if="item.displayid === inputvalue"/>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/check'

export default {
    name: 'TravelClassSelection',
    props: {
        value: Number,
        radioitems: {
            type: Array, // [{ displayid: 'xx', displayname: 'yy'}]
        },
        name: {
            type: String,
            default: 'travelclass'
        }
    },
    computed: {
        inputvalue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
  }
}
</script>

<style scoped>
.selection-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: fit-content;
}

.selection-container > .item {
    position: relative;
    background: #fff;
    font-size: 1.2em;
    min-width: 125px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    box-shadow: 0px 0px 5px rgb(100 100 150 / 30%);
}

.selection-container > .item:hover,
.selection-container > .item:hover >>> * {
    cursor: pointer;
}

.selection-container > .item:hover {
    box-shadow: 0px 0px 7px rgb(100 100 150 / 50%);
}

.selection-container > .item.first {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.selection-container > .item.last {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.selection-container > .item.active {
    color: #fff;
    background-color: #0069B4;
}

.selection-container > .item > input {
    display: none;
}

.selection-container > .item > label {
    width: 100%;
    display: inline-block;
}

.selection-container > .item > svg {
    position: absolute;
    left: 6px;
    top: 6px;
}
</style>