<template>
    <div class="resultbox-container">
        <div class="type-selection">
            <div class="type-tab" @click="activateTab('TRANSIT')" :class="{ 'active' : activetype === 'TRANSIT' }">
                <v-icon name="train" :scale="iconscale" />
            </div>
            <div class="type-tab" @click="activateTab('ONDEMAND')" :class="{ 'active' : activetype === 'ONDEMAND' }" >
                <v-icon name="taxi" :scale="iconscale" />
            </div>
            <div class="type-tab" @click="activateTab('CYCLING')" :class="{ 'active' : activetype === 'CYCLING' }">
                <v-icon name="bicycle" :scale="iconscale + 0.4" /> <!-- the bicycle icon seems smaller than the others -->
            </div>
            <div class="type-tab" @click="activateTab('WALKING')" :class="{ 'active' : activetype === 'WALKING' }" >
                <v-icon name="walking" :scale="iconscale" />
            </div>
            <div class="type-tab" @click="activateTab('DRIVING')" :class="{ 'active' : activetype === 'DRIVING' }">
                <v-icon name="car" :scale="iconscale" />
            </div>
        </div>
        <div class="result-list">
            <div v-if="resultdata && !typeloading">
                <div class="card-header">
                    <label>{{ $t('landing.result.to') }}<span>{{ this.tolocation }}</span></label>
                </div>
                <ResultCard 
                    v-for="(trip, tripidx) in resultdata.trips"
                    :key="tripidx"
                    @click="resultSelected($event, tripidx)"
                    @ticketsClicked="openTickets"
                    @detailsClicked="openDetails"
                    :ticketsonly="activetype === 'ONDEMAND'"
                    :withstarttime="activetype === 'TRANSIT'"
                    :showdetails="tripidx === selectedresultid"
                    :trip="trip"
                    :details="activetype === 'TRANSIT'"
                    class="resultcard"/>
            </div>
            <div v-if="!resultdata && !typeloading" class="error-container">
                <v-icon name="exclamation-circle" scale="2"/>
                <span>
                    <p>{{ $t('landing.result.error.headline') }}</p>
                    <p>{{ $t('landing.result.error.detail') }}</p>
                </span>
            </div>
            <div v-if="typeloading" class="typeloading">
                <LoadingEllipsis />
            </div>
        </div>
    </div>
</template>

<script>
import ResultCard from './ResultCard.vue'
import LoadingEllipsis from './elements/LoadingEllipsis.vue'

import 'vue-awesome/icons/train'
import 'vue-awesome/icons/car'
import 'vue-awesome/icons/bicycle'
import 'vue-awesome/icons/walking'
import 'vue-awesome/icons/taxi'
import 'vue-awesome/icons/exclamation-circle'

export default {
    name: 'ResultBox',
    props: {
        results: [],
        tolocation: String,
    },
    data() {
        return {
            activetype: 'TRANSIT',
            iconscale: 1.4,
            selectedresult: null,
            selectedresultid: 0
        }
    },
    mounted() {
        // initially emit the first result
        if (this.resultdata) {
            this.resultSelected(this.resultdata.trips[0])
        }
    },
    methods: {
        activateTab: function(triptype) {
            this.activetype = triptype
            
            if (this.resultdata) {
                // show first result on map, if there is any data
                this.resultSelected(this.resultdata.trips[0])
            }
        },
        resultSelected: function(event, tripidx = 0) {
            event.type = this.activetype
            this.selectedresult = event
            this.selectedresultid = tripidx
            this.$emit('tripselected', event)
        },
        openDetails: function(event) {
            this.$emit('detailsClicked', event)
        },
        openTickets: function(event) {
            this.$emit('ticketsClicked', event)
        }
    },
    computed: {
        resultdata: function() {
            if (this.results && this.results.length !== 0) {
                // the entry can be undefined, if there was no result from the service
                return this.results.find(entry => entry.type === this.activetype && entry.data !== null)
            }
            return null
        },
        typeloading: function() {
            if (this.results) {
                // the type is loading if its not yet in the result array
                return !this.results.find(entry => entry.type === this.activetype)
            } else {
                return true
            }
        }
    },
    watch: {
        typeloading: function(value) {
            // trigger tab selection again, after the type has finished
            // loading, so the first route will be selected
            if (!value) this.activateTab(this.activetype)
        }
    },
    components: {
        ResultCard,
        LoadingEllipsis
    }
}
</script>

<style scoped>
.resultbox-container {
    width: 100%;
    height: 100%;
}

.resultbox-container > .type-selection {
    background: #064A6C;
    height: 50px;
    display: flex;
    justify-content: space-around;
}

.resultbox-container > .result-list {
    background: #33AAFF;
    padding-bottom: 30px;
    height: calc(100% - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.resultbox-container > .result-list > .typeloading {
    margin-top: 50px;
}

.resultbox-container > .result-list > .error-container {
    color: #fff;
    margin: 20px;
}

.resultbox-container > .result-list > .error-container > svg {
    width: 100%;
    margin-bottom: 10px;
}

.resultbox-container > .type-selection > .type-tab {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.resultbox-container > .type-selection > .type-tab:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, .2);
}

.resultbox-container > .type-selection > .type-tab.active {
    background: #33AAFF;
}

.resultbox-container > .type-selection > .type-tab > svg {
    color: #fff;
    margin: auto;
}

.card-header {
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    margin-left: 20px;
    padding-top: 10px;
}

.card-header > label > span {
    font-weight: bold;
}

.card-header > label > span {
    font-weight: bold;
    margin-left: 5px;
}
</style>