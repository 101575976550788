<template>
    <div id="page-container">
        <div v-if="isloading" class="loader">
            <LoadinEllipsis />
        </div>

        <div class="navigation" @click="goBack">
            <v-icon name="chevron-left"/>
            <label>{{ $t('checkout.buttons.goback') }}</label>
        </div>
        <div class="header">
            <label>{{ $t('checkout.header') }}</label>
        </div>

        <div v-show="!isloading && registerguest">
            <div class="personal-data-container">
                <div class="info">
                    <span v-html="this.info"></span>
                </div>

                <div class="input-container">
                    <TextInput class="input" v-model="firstname" :label="$t('checkout.personal.firstname')" :required="true" :error="$v.firstname.$error" :errortext="$t('checkout.personal.error.nofirstname')"/>
                </div>

                <div class="input-container">
                    <TextInput class="input" v-model="lastname" :label="$t('checkout.personal.lastname')" :required="true" :error="$v.lastname.$error" :errortext="$t('checkout.personal.error.nolastname')"/>
                </div>

                <div class="input-container">
                    <TextInput class="input" v-model="email" :label="$t('checkout.personal.email')" :required="true" :error="$v.email.$error" :errortext="$t('checkout.personal.error.noemail')"/>
                </div>

                <div class="input-container">
                    <TextInput class="input" v-model="$v.emailconfirmation.$model" :label="$t('checkout.personal.emailconfirmation')" :required="true" :error="$v.emailconfirmation.$error" :errortext="$t('checkout.personal.error.emailsdonotmatch')"/>
                </div>

                <div class="button-container">
                    <input type="button" :value="$t('checkout.personal.continue')" @click="personalDataComplete"/>
                </div>
            </div>
        </div>

        <!-- this has to be a v-show, so the paypal button can be rendered, even if additional data is needed -->
        <div v-show="!isloading && !registerguest">
            <div class="buyer-info">
                <div class="buyer-header">
                    {{ $t('checkout.buyer.header') }}
                    <v-icon name="edit" @click="backToRegister" v-if="this.firstname && this.lastname && this.email"/>
                </div>

                <div class="info">
                    <label>{{ this.buyer.firstname }} {{ this.buyer.lastname }}</label>
                    <label>{{ this.buyer.mail }}</label>
                </div>
            </div>

            <div class="tickets">
                <div class="ticket-body">
                    <div class="ticket-header">
                        <label>{{ $t('checkout.tickets.title') }}</label>
                    </div>

                    <div class="ticket-list" v-for="ticket in tickets" :key="ticket.id">
                        <div class="name">
                            <label>{{ ticket.data.ticketName }}</label>
                        </div>
                        <div class="price">
                            <label><span>{{ ticket.amount }}x</span><br />{{ _currencyValueToFloat(ticket.data.price) }} {{ _getCurrencySymbol(ticket.data.currency) }}</label>
                        </div>
                    </div>

                    <div class="total" v-if="!isloading">
                        <label>{{ $t('checkout.tickets.total') }}<br /><span>{{ total }} {{ totalcurrency }}</span></label>
                    </div>
                </div>
            </div>

            <div class="actions">
                <div class="payment">
                    <div class="option" :class="{ 'selected' : this.paymenttype === paymentmethods.PAYPAL }">
                        <label for="paypal">
                            <div>
                                <label class="payment-title">{{ $t('checkout.methods.paypal').toUpperCase() }}</label>
                                <br />
                                <img src="@/assets/icons/paypal_logo.png" id="paypal-logo"/>
                                <br />
                                <input type="radio" id="paypal" name="payment" :value="paymentmethods.PAYPAL" v-model="paymenttype" >
                            </div>
                        </label>
                    </div>
                    <div v-if="this.$config.pspconfig.cc" class="option" :class="{ 'selected' : this.paymenttype === paymentmethods.CC }">
                        <label for="cc">
                            <div>
                                <label class="payment-title">{{ $t('checkout.methods.cc').toUpperCase() }}</label>
                                <br />
                                <img src="@/assets/icons/cc_logo.png" id="cc-logo"/>
                                <br />
                                <input type="radio" id="cc" name="payment" :value="paymentmethods.CC" v-model="paymenttype" @change="setupPayment">
                            </div>
                        </label>
                    </div>
                    <div v-if="this.$config.pspconfig.sepa" class="option" :class="{ 'selected' : this.paymenttype === paymentmethods.SEPA }">
                        <label for="sepa">
                            <div>
                                <label class="payment-title">{{ $t('checkout.methods.sepa').toUpperCase() }}</label>
                                <br />
                                <img src="@/assets/icons/sepa_logo.svg" id="sepa-logo"/>
                                <br />
                                <input type="radio" id="sepa" name="payment" :value="paymentmethods.SEPA" v-model="paymenttype" @change="setupPayment">
                            </div>
                        </label>
                    </div>
                    <div v-if="this.$config.pspconfig.googlepay" class="option" :class="{ 'selected' : this.paymenttype === paymentmethods.GOOGLEPAY }">
                        <label for="google-pay">
                            <div>
                                <label class="payment-title">{{ $t('checkout.methods.googlepay').toUpperCase() }}</label>
                                <br />
                                <img src="@/assets/icons/google_pay_logo.png" id="google-pay-logo" />
                                <br />
                                <input type="radio" id="google-pay" name="payment" :value="paymentmethods.GOOGLEPAY" v-model="paymenttype" @change="setupPayment">
                            </div>
                        </label>
                    </div>
                    <div v-if="this.$config.pspconfig.applepay" class="option" :class="{ 'selected' : this.paymenttype === paymentmethods.APPLEPAY }">
                        <label for="apple-pay">
                            <div>
                                <label class="payment-title">{{ $t('checkout.methods.applepay').toUpperCase() }}</label>
                                <br />
                                <img src="@/assets/icons/apple_pay_logo.png" id="apple-pay-logo" />
                                <br />
                                <input type="radio" id="apple-pay" name="payment" :value="paymentmethods.APPLEPAY" v-model="paymenttype" @change="setupPayment">
                            </div>
                        </label>
                    </div>
                </div>

                <div class="confirm">
                    <!-- <div ref="paypal" v-show="this.paymenttype === 0"></div> -->
                    <div id="paypal-button" v-show="this.paymenttype === paymentmethods.PAYPAL"></div>

                    <!-- <input v-if="this.paymenttype === 1" type="button" value="Confirm and pay" @click="payWithCc"> -->
                    <!-- <input v-if="this.paymenttype === 2" type="button" value="Confirm and pay" @click="payWithSepa"> -->

                    <div v-show="this.paymenttype !== 0">
                        <form id="telecash" method="post" :action="telecashurl">
                            <input type="hidden" name="chargetotal" :value="total" />
                            <input type="hidden" name="checkoutoption" value="combinedpage"/>
                            <input type="hidden" name="currency" value="978" />
                            <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
                            <input type="hidden" name="mode" value="payonly" />
                            <input type="hidden" name="paymentMethod" :value="mapToTeleCashPaymentMethod(this.paymenttype)" />
                            <input type="hidden" name="responseFailURL" :value="returnurlsuccess"/>
                            <input type="hidden" name="responseSuccessURL" :value="returnurlfail"/>
                            <input type="hidden" name="storename" :value="storename" />
                            <input type="hidden" name="timezone" value="Europe/Berlin" />
                            <input type="hidden" name="txndatetime" :value="transactiontime" />
                            <input type="hidden" name="txntype" value="sale" />
                            <input type="hidden" name="hashExtended" :value="transactionhash"/>

                            <input type="submit" class="button" :value="$t('checkout.buttons.confirmandpay')" :disabled="!telecashavailable || gettinghash">
                            <v-icon name="spinner" class="spinner spin" v-show="gettinghash"/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from '../components/systems/elements/TextInput.vue'
import LoadinEllipsis from '../components/systems/elements/LoadingEllipsis.vue'

import Vue from 'vue'
import axios from 'axios'
import { required, sameAs } from 'vuelidate/lib/validators'
import getEnv from '@/utils/env'

import 'vue-awesome/icons/chevron-left'
import 'vue-awesome/icons/credit-card'
import 'vue-awesome/icons/spinner'
import 'vue-awesome/icons/edit'

export default {
    name: 'CheckoutPage',
    data() {
        return {
            paymenttype: 0,
            telecashurl: this.$config.telecashconfig.url,
            storename: this.$config.telecashconfig.storename,
            transactiontime: null,
            transactionhash: null,
            isloading: false,
            total: null,
            totalcurrency: null,
            returnurlsuccess: this.$config.telecashconfig.returnsuccess,
            returnurlfail: this.$config.telecashconfig.returnfail,
            gettinghash: true,
            telecashavailable: false,
            registerguest: false,
            firstname: null,
            lastname: null,
            email: null,
            emailconfirmation: null,
            guesttoken: null,
            scriptid: 'paypal-sdk',
            paymentmethods: {
                PAYPAL: 0,
                CC: 1,
                SEPA: 2,
                GOOGLEPAY: 3,
                APPLEPAY: 4
            }
        }
    },
    validations: {
        firstname: {
            required
        },
        lastname: {
            required
        },
        email: {
            required
        },
        emailconfirmation: {
            required,
            sameAsPassword: sameAs('email')
        }
    },
    mounted() {
        this.isloading = true

        if (this.$route.query.error) {
            Vue.$toast.error(this.$route.query.error)
            this.$router.replace('/checkout')
        }
        
        if (!this.$keycloak || !this.$keycloak.ready) {
            this.$keycloak.onReadyCallback = () => {
                this.init()
            }
        } else {
            this.init()
        }
    },
    methods: {
        init: function() {
            if (this.$route.query.success) {
                let payment = this.$store.state.cart.items[0].payment
                payment.paymentdata = this.$route.query.oid
                this.$cart.updatePaymentInfo(payment)
                this.completeOrder()
                this.$router.replace('/checkout')
            } else if (this.$store.state.cart.items.length === 0) {
                this.goBack()
            }

            // only init the page (and paypal for that matter) if we're not
            // routing for success /s

            // paypal setup
            if (!document.getElementById(this.scriptid)) {
                const script = document.createElement('script')
                script.src = 'https://www.paypalobjects.com/api/checkout.js'
                script.id = this.scriptid
                script.addEventListener('load', this.setupPaypal)
                document.body.append(script)
            } else {
                this.setupPaypal()
            }

            this.total = this._currencyValueToFloat(this.$cart.getTotalPrice())
            this.totalcurrency = this._getCurrencySymbol(this.$cart.getCurrency())

            // if the user is not authenticated, we have a guest
            // --> show additional data form
            if (!this.$keycloak.authenticated) {
                this.registerguest = true
            }

            this.isloading = false
        },
        personalDataComplete: function() {
            this.$v.$touch()

            if (this.$v.$anyError) {
                return
            }

            this.isloading = true

            this.guesttoken = `GUEST-${this._rng(20)}`

            // create user profile
            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_ACCOUNT_USERPROFILE_ENDPOINT
            url += `/${this.guesttoken}`

            const body = {
                info: {
                    email: this.email,
                    firstname: this.firstname,
                    lastname: this.lastname
                }
            }

            axios.patch(url, body)
            .then(() => {
                // update cart with the guest info
                this.$cart.updatePayerInfo(this.firstname, this.lastname)
                this.registerguest = false
                this.isloading = false
            })
            .catch(() => {
                this.isloading = false
                // most likely the email was already used
                Vue.$toast.error(this.$t('checkout.error.registerguest'))
            })
        },
        setupPayment: function(event) {
            const method = parseInt(event.target.value, 10)

            switch(method) {
                case this.paymentmethods.CC:
                    console.log('cc')
                    if (this.$config.pspconfig.cc) {
                        this.setupTeleCash(method)
                    } else {
                        this.setupLogPay(method)
                    }
                    break;
                case this.paymentmethods.SEPA:
                    if (this.$config.pspconfig.sepa) {
                        this.setupTeleCash(method)
                    } else {
                        this.setupLogPay(method)
                    }
                    break;
                case this.paymentmethods.GOOGLEPAY:
                    if (this.$config.pspconfig.googlepay) {
                        this.setupTeleCash(method)
                    } else {
                        // TODO: this.setupLogPay(method)
                    }
                    break;
                case this.paymentmethods.APPLEPAY:
                    if (this.$config.pspconfig.applepay) {
                        this.setupTeleCash(method)
                    } else {
                        // TODO: this.setupLogPay(method)
                    }
                    break;
                default:
                    throw 'Setup | Unknown payment method: ' + method
            }
        },
        setupLogPay: function(method) {
            this.gettinghash = true

            let logpaymethod = undefined

            switch(method) {
                case this.paymentmethods.CC:
                    logpaymethod = 'PASSCC'
                    break;
                case this.paymentmethods.SEPA:
                    logpaymethod = 'GIRPAY'
                    break;
                default:
                    throw 'LogPay | Unknown payment method: ' + method
            }

            const paymentmethod = logpaymethod
            const total = this.$cart.getTotalPrice()
            const items = []

            this.$store.state.cart.items.forEach(item => {
                items.push({
                    id: item.id,
                    name: item.payment.product.prodname,
                    price: item.amount * item.payment.product.price,
                    quantity: item.amount
                })
            })

            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_PAYMENT_LOGPAY_PAYMENT_URL
            url += `?method=${paymentmethod}`
            url += `&total=${total}`
            url += `&items=${JSON.stringify(items)}`

            axios.get(url)
            .then(/*response*/() => {
                this.gettinghash = false
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        mapToTeleCashPaymentMethod(method) {
            let telecashmethod = undefined

            switch(method) {
                case this.paymentmethods.CC:
                    telecashmethod = 'V'
                    break;
                case this.paymentmethods.SEPA:
                    telecashmethod = 'debitDE'
                    break;
                case this.paymentmethods.GOOGLEPAY:
                    telecashmethod = 'googlePay'
                    break;
                case this.paymentmethods.APPLEPAY:
                    telecashmethod = 'applePay'
                    break;
                default:
                    break;
            }

            return telecashmethod
        },
        setupTeleCash: function(method) {
            this.gettinghash = true
            this.telecashavailable = false
            // telecash setup
            let now = new Date()
            // Y:m:d-H:i:s
            const year    = now.getFullYear()
            const month   = this._leadingZero(now.getMonth()+1)
            const day     = this._leadingZero(now.getDate())
            const hours   = this._leadingZero(now.getHours())
            const minutes = this._leadingZero(now.getMinutes())
            const seconds = this._leadingZero(now.getSeconds())
            this.transactiontime = `${year}:${month}:${day}-${hours}:${minutes}:${seconds}`

            let paymentmethod = this.mapToTeleCashPaymentMethod(method)

            if (!paymentmethod) {
                throw 'TeleCash | Unknown payment method: ' + method
            }

            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_PAYMENT_TELECASH_VERIFY
            url += `?total=${this.total}`
            url += `&txntime=${this.transactiontime}`
            url += `&method=${paymentmethod}`

            axios.get(url)
            .then(response => {
                if (response.status === 200) {
                    this.transactionhash = response.data
                    this.gettinghash = false
                    this.telecashavailable = true
                } else {
                    Vue.$toast.warning(response.statusText)
                    this.gettinghash = false
                    this.telecashavailable = false
                }

                // update cart with current payment provider info
                let payment = {
                    paymentdata: null,
                    paymentprovider: 8,
                    userToken: this.usertoken,
                    // TODO: map for middleware?
                    paymenttype: this.paymenttype === 1 ? 12 : 18, // KREDITKARTE == VISA == 12, SOFOTUEBERWEISUNG == debitDE == 18
                }

                this.$cart.updatePaymentInfo(payment)
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        setupPaypal: function() {
            const price = this._currencyValueToFloat(this.$cart.getTotalPrice())
            const inner = this

            window.paypal.Button.render({
                // Configure environment
                env: 'sandbox',
                client: {
                    sandbox: inner.$config.paypalconfig.clientid,
                    production: 'demo_production_client_id'
                },
                // Customize button (optional)
                locale: 'de_DE',
                style: {
                    size:  'large',
                    color: 'black',
                    shape: 'rect',
                },

                // Enable Pay Now checkout flow (optional)
                commit: true,

                // Set up a payment
                payment: function(data, actions) {
                    return actions.payment.create({
                        transactions: [{
                            amount: {
                                total: price,
                                currency: inner.$config.paypalconfig.currency
                            }
                        }]
                    })
                },
                // Execute the payment
                onAuthorize: function(data, actions) {
                    return actions.payment.execute()
                    .then(function() {
                        let payment = {
                            paymentdata: data.paymentID, // payment id
                            paymentprovider: 5, // Paypal
                            userToken: inner.usertoken,
                            paymenttype: 5, // Paypal
                        }

                        inner.$cart.updatePaymentInfo(payment)

                        inner.completeOrder()
                    })
                },
                onCancel: function() {
                    Vue.$toast.info(inner.$t('general.info.paymentaborted'))
                },
                onError: function(err) {
                    // catching a render error, if the user is redirected
                    // caused by an empty card and direct access to '/checkout'
                    if (!err.includes('#paypal-button')) {
                        Vue.$toast.error(err)
                    }
                }
            }, '#paypal-button')
        },
        goBack: function() {
            this.$router.push('/')
        },
        completeOrder: function() {
            this.isloading = true

            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_COMPLETE_ORDER
            let body = []

            this.$store.state.cart.items.forEach(item => {
                for (let it = 0; it < item.amount; it++){
                    body.push(item.payment)
                }

            })

            axios.post(url, body)
            .then(response => {
                // TODO: currently we can only buy one ticket
                let ticketdata = response.data[0]
                // complete order does not return the price, so we just cache it
                let ticketprice = this.$store.state.cart.items[0].data.price
                // push last ticket into cart, so we can display it if we like to
                this.$cart.setLastPurchase(ticketdata, ticketprice)

                this.$cart.clearCart()

                this.isloading = false

                this.$router.push('/?showticket=true') // param to auto load the last ticket
                Vue.$toast.info(this.$t('general.info.ticketsordered'))
            })
            .catch(err => {
                this.isloading = false
                Vue.$toast.error(err)
            })
        },
        backToRegister: function() {
            this.registerguest = true
        }
    },
    watch: {
        isloading: function(newvalue) {
            this.$store.commit('newFooterLoading', newvalue)
        }
    },
    computed: {
        tickets: function() {
            return this.$store.state.cart.items
        },
        usertoken: function() {
            if (this.$keycloak
                && this.$keycloak.authenticated
                && this.$keycloak.tokenParsed) {
                return this.$keycloak.tokenParsed.sub
            } else {
                return this.guesttoken
            }
        },
        info: function() {
            let result = ''
            let text = this.$t('checkout.personal.info')
            // keeping the delimiter
            text = text.split(/([.?!])/g)
            text.forEach(entry => {
                result += entry
                if (entry.length === 1) {
                    result += '</p><p>'
                }
            })

            return `<p>${result}</p>`

        },
        buyer: function() {
            let buyer = {
                firstname: 'NO',
                lastname: 'USER',
                mail: 'FOUND'
            }

            if (this.$keycloak.authenticated
                    && this.$keycloak.tokenParsed) {
                buyer.firstname = this.$keycloak.tokenParsed.given_name
                buyer.lastname  = this.$keycloak.tokenParsed.family_name
                buyer.mail      = this.$keycloak.tokenParsed.email
            } else {
                buyer.firstname = this.firstname
                buyer.lastname  = this.lastname
                buyer.mail      = this.email
            }

            return buyer
        }
    },
    components: {
        LoadinEllipsis,
        TextInput
    }
}
</script>

<style scoped>
#page-container {
    margin: auto;
    height: 100%;
    width: 90%;
    max-width: 800px;
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 50%);
    overflow-y: auto;
    box-sizing: border-box;
    color: #2c2e2f;
}

.loader {
    position: absolute;
    height: 100%;
    top: 0;
    background: #000000bb;
    display: flex;
    align-items: center;

    left: 0;
    width: 100%;

    /* width: calc(100% - 10px);
    max-width: 760px;
    margin: auto; */
}

.loader > div {
    margin: auto;
}

.navigation {
    display: flex;
    align-items: center;
    font-size: 1.3em;
    color: #666;
    margin-top: 10px;
    width: fit-content;
    padding: 3px;
    border-radius: 5px;
    box-sizing: border-box;
}

.navigation:hover {
    cursor: pointer;
    background: rgba(0,0,0,.1);
}

.navigation > label {
    margin-left: 10px;
    font-weight: bold;
}

.navigation > label:hover {
    cursor: pointer;
}

.header {
    margin: 20px 0 50px 0;
    font-size: 2.5em;
    font-weight: bold;
}

.tickets > .ticket-body {
    position: relative;
    min-height: 100px;
    /* background: linear-gradient(#064D70, #06608D,#064D70); */
    box-shadow: 0px 1px 10px rgb(100 100 150 / 50%);
    border-radius: 20px;
    padding: 10px 30px;
}

/* .tickets > .ticket-body:after,
.tickets > .ticket-body:before {
    position: absolute;
    width: 20px;
    height: 40px;
    background-color: #fff;
    content: "";
    top: calc(50% - 20px);
}

.tickets > .ticket-body:after {
    right: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.tickets > .ticket-body:before {
    left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
} */

.tickets > .ticket-body > .ticket-header {
    color: #2c2e2f;
    text-align: left;
    font-size: 1.2em;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid;
    font-weight: bold;
}

.tickets > .ticket-body > .ticket-list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff11;
    padding: 5px 0;
}

.tickets > .ticket-body > .ticket-list > .price {
    flex: 3;
    font-size: 1.7em;
    text-align: right;
    margin-right: 30px;
}

.tickets > .ticket-body > .ticket-list > .price > label > span {
    font-size: .7em;
}

.tickets > .ticket-body > .ticket-list > .name {
    flex: 7;
    text-align: left;
    font-size: 1.2em;
}

.tickets > .ticket-body > .total {
    text-align: right;
    padding: 10px 50px 0 0;
    border-top: 5px double;
}

.tickets > .ticket-body > .total > label > span {
    font-size: 2.5em;
    font-weight: bold;
}

.actions {
    margin-top: 50px;
}

.actions > .payment {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px
}

.actions > .payment > .option {
    /* width: 30%; */
    min-width: 190px;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 10px;
    padding: 5px;
    height: 100px;
    /* border: 2px solid #ddd; */
    box-shadow: 0px 1px 10px rgb(100 100 150 / 50%);
}

.actions > .payment > .option > label:hover,
.actions > .payment > .option.selected,
.actions > .payment > .option:hover {
    background: rgba(0,0,0,.1);
    cursor: pointer;
}

.actions > .payment > .option > label > div > input {
    visibility: hidden;
}

.actions > .payment > .option > label > div > img {
    height: 70px;
    width: 100%;
    object-fit: contain;
    margin: auto;
}

.actions > .payment > .option > label > div > .payment-title {
    font-weight: bold;
    color: #064A6C;
}

#paypal-logo {
    box-sizing: border-box;
    padding: 15px;
}

#cc-logo {
    box-sizing: border-box;
}

#sepa-logo {
    box-sizing: border-box;
    padding: 15px;
}

#google-pay-logo {
    box-sizing: border-box;
    padding: 10px;
}

#apple-pay-logo {
    box-sizing: border-box;
    padding: 10px;
}

.actions > .confirm {
    margin: 50px 0;
}

.actions > .confirm > div > form {
    position: relative;
}

.actions > .confirm > div > form > input.button {
    background: #2c2e2f;
    width: 350px;
    height: 45px;
}

.actions > .confirm > div > form > .spinner {
    color: #2c2e2f;
    position: absolute;
    margin-left: 20px;
    top: 13px;
}

.actions > .confirm > div > form > input:not([disabled]):hover {
    background: #2c2e2fcc;
    cursor: pointer;
}

.personal-data-container {
    width: 90%;
    height: 500px;
    margin: auto;
    text-align: left;
}

.personal-data-container > .info {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.2em;
}

.personal-data-container > .input-container {
    max-width: 400px;
    margin: auto;
}

.personal-data-container > .button-container {
    text-align: center;
    margin-top: 30px;
}

.personal-data-container > .button-container > input {
    color:#064A6C;
    margin: auto;
}

.personal-data-container > .button-container > input:hover {
    background: #2c2e2f22;
}

.buyer-info {
    text-align: left;
    margin-left: 10px;
}

.buyer-info > .buyer-header {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
}

.buyer-info > .buyer-header > svg {
    color: #aaa;
}

.buyer-info > .buyer-header > svg:hover {
    cursor: pointer;
}

.buyer-info > .info {
    margin-bottom: 40px;
    font-size: 1.2em;
}

.buyer-info > .info > div {
    flex: 5;
    margin-top: 10px;
    padding: 2px 5px;
}

.buyer-info > .info > label {
    display: block;
    margin: 5px 0 0 10px;
}

@media(max-width: 650px) {
    #page-container {
        width: 100%;
    }
}
</style>