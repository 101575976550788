<template>
    <div class="cart-container">
        <div class="items" v-if="items.length > 0">
            <div class="cart-item" v-for="item in items" :key="item.id">
                <div>
                    <label>{{ `${item.amount}x`}}</label>
                </div>
                <div class="price">
                    <label>{{`${_currencyValueToFloat(item.data.price)} ${_getCurrencySymbol(item.data.currency)}`}}</label>
                </div>
                <div class="name">
                    <label>{{ item.data.ticketName }}</label>
                </div>
                <v-icon class="delete-icon" name="trash-alt" @click="deleteItem(item.id)"/>
            </div>
        </div>
        <div v-else class="empty">
            <label>{{ $t('sandbar.cart.empty') }}</label>
        </div>
        <div class="actions">
            <div class="total">
                <label>
                    {{ $t('sandbar.cart.total') }}<br />
                    <span>{{ `${this._currencyValueToFloat(this.$cart.getTotalPrice())} €` }}</span>
                </label>
            </div>
            <div class="checkout">
                <br />
                <input type="button" :value="$t('sandbar.cart.checkout')" @click="goToCheckout" :disabled="items.length === 0"/>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/ticket-alt'
import 'vue-awesome/icons/trash-alt'

export default {
    name: 'ShoppingCartInset',
    props: {
        items: []
    },
    methods: {
        deleteItem: function(id) {
            this.$cart.removeItemFromCart(id)
        },
        goToCheckout: function() {
            this.$router.push('/checkout')
        }
    }
}
</script>

<style scoped>
.cart-container {
    width: 100%;
    height: 100%;
}

.cart-container > .empty {
    margin: 10px 0 30px 0;
    font-size: 1.3em;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 20px;
}

.cart-container > .items {
    height: 85%;
    overflow-y: auto;
}

.cart-container > .actions {
    height: 15%;
    display: flex;
    align-items: center;
}

.cart-container > .actions > div {
    padding: 0 5px;
}

.cart-container > .actions > .total {
    flex: 5;
    text-align: right;
}

.cart-container > .actions > .total > label {
    font-size: 1.1em;
}

.cart-container > .actions > .total > label > span {
    margin-left: 10px;
    font-weight: bold;
    font-size: 1.4em;
}

.cart-container > .actions > .checkout {
    flex: 5;
    text-align: left;
}

.cart-container > .actions > .checkout > input {
    color: #064A6C;
}

.cart-container > .actions > .checkout > input:not([disabled]):hover {
    background: #064A6C22;
}

.cart-container > .actions > .checkout > input:disabled {
    color: #ccc;
}

.cart-container > .items > .cart-item {
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 5px 0;
    box-sizing: border-box;
}

.cart-container > .items > .cart-item > .delete-icon {
    flex: 1;
    color: red;
    height: 20px;
    width: 20px;
    padding: 10px 5px;
    border-radius: 5px;
}

.cart-container > .items > .cart-item > .delete-icon:hover {
    cursor: pointer;
    background: rgba(0,0,0,.1);
}

.cart-container > .items > .cart-item > .ticket-icon {
    flex: 1;
    color: #064A6C;
}

.cart-container > .items > .cart-item > .price {
    flex: 2;
    font-size: 1.3em;
    font-weight: bold;
    text-align: right;
    padding-right: 10px;
}

.cart-container > .items > .cart-item > .name {
    flex: 6;
    text-align: left;
    font-size: 1.2em;
}
</style>