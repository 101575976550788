<template>
    <div id="page-container">
        <Sidebar @fromselected="fromSelected" @toselected="toSelected" @tripselected="handleTripSelection" @reset="resetSearch" />

        <AccountSandbar @lastTicket="showTicketPopup" />

        <LMap :bounds="bounds" class="map" :zoom="zoom" :center="center" :options="mapOptions" @update:center="centerUpdate" @update:zoom="zoomUpdate">
            <LTileLayer :url="url" :attribution="attribution" />
            <LControlZoom :position="zoomPosition" />
            <LFeatureGroup v-if="markerlist">
                <LMarker v-for="(marker, idx) in markerlist" :key="idx" :lat-lng="marker.position" :icon="icon"/>
                <LPolyline :lat-lngs="polyline" :visible="true"/>
            </LFeatureGroup>
        </LMap>

        <LastTicketDepiction :ticket="ticket" :open="showticket" @close="hideTicketPopup"/>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'

import Sidebar from '../components/Sidebar.vue'
import AccountSandbar from '../components/systems/AccountSandbar.vue'
import LastTicketDepiction from '../components/systems/LastTicketDepiction.vue'

import {
    latLng,
    latLngBounds,
    icon
} from "leaflet"

import {
    LMap,
    LTileLayer,
    LControlZoom,
    // LControl,
    LMarker,
    LFeatureGroup,
    LPolyline,
    // LIcon,
    // LPopup,
    // LTooltip
} from "vue2-leaflet"

export default {
    name: 'Landing',
    data() {
        return {
            zoom: 13,
            center: latLng(this.$config.osmconfig.startlat, this.$config.osmconfig.startlng),
            url: `${this.$config.osmconfig.tileserver}/{z}/{x}/{y}.png`, // https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
            attribution: `<a href="${this.$config.osmconfig.attributionlink}">${this.$config.osmconfig.attribution}</a>`,
            zoomPosition: 'bottomright',
            mapOptions: {
                zoomSnap: 0.5,
                zoomControl: false
            },
            frommarker: null,
            tomarker: null,
            bounds: null,
            icon: icon({
                iconUrl: require("../../src/assets/icons/mytraq_marker.png"),
                iconSize: [40, 40]
            }),
            polyline: [],
            keycloakurl: '',
            showticket: false
        }
    },
    mounted() {
        this.keycloakurl = this.$config.keycloakconfig.url

        if (this.$route.query.showticket && this.ticket) {
            this.$router.push('/')
            this.showticket = true
        }
    },
    computed: {
        markerlist: function() {
            let list = []
            if (this.frommarker) list.push(this.frommarker)
            if (this.tomarker) list.push(this.tomarker)
            if (list.length === 2) this.newPolyLine([list[0].position, list[1].position])
            return list
        },
        ticket: function() {
            return this.$store.state.cart.lastpurchase
        }
    },
    watch: {
        markerlist: function(value) {
            if (value && value.length === 2) {
                this.bounds = latLngBounds(value[0].position, value[1].position)
            } else {
                this.bounds= null
            }
        }
    },
    methods: {
        showTicketPopup: function() {
            this.showticket = true
        },
        hideTicketPopup: function() {
            this.showticket = false
        },
        centerUpdate: function(center) {
            this.currentCenter = center;
        },
        zoomUpdate: function(zoom) {
            this.currentZoom = zoom;
        },
        fromSelected: function(event) {
            if (event) {
                let position = latLng(event.position.latitude, event.position.longitude)
                let marker = {
                    display: event.name,
                    position: position
                }
                this.frommarker = marker
                this.center = position
            } else {
                this.frommarker = null
            }
        },
        toSelected: function(event) {
            if (event) {
                let position = latLng(event.position.latitude, event.position.longitude)
                let marker = {
                    display: event.name,
                    position: position
                }
                this.tomarker = marker
                this.center = position
            } else {
                this.tomarker = null
            }
        },
        newPolyLine: function(event) {
            this.polyline = event
        },
        handleTripSelection: function(event) {
            let coordinates = []
            event.legs.forEach(entry => {
                entry.projection.forEach(position => {
                    coordinates.push(latLng(position.latitude, position.longitude))
                })
            })

            this.newPolyLine(coordinates)
        },
        resetSearch: function() {
            this.newPolyLine([])
        }
    },
    components: {
        LMap,
        LTileLayer,
        LControlZoom,
        // LControl,
        LMarker,
        LFeatureGroup,
        LPolyline,
        // LIcon,
        // LPopup,
        // LTooltip
        Sidebar,
        AccountSandbar,
        LastTicketDepiction
    },
}
</script>

<style scoped>
#page-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}

.map {
    height: 100%;
    z-index: 0;
    flex: 1;
}

.leaflet-control {
    margin: 0;
}

@media(max-width: 650px) {
    .map {
        height: calc(100% - 275px);
        margin-top: 275px;
    }
}
</style>

<style>
#kcframe {
    position: fixed;
    z-index: 500;
    top: 100px;
    left: 800px;
    background: #fff;
    border: none;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 70%);
}
</style>