<template>
    <div class="ticket-container">
        <div class="ticket-item" @click="openTicket">
            <v-icon class="icon" name="ticket-alt" scale="1.5" />

            <div>
                <div class="name-container">
                    <label >{{ this.ticket.name }}</label>
                </div>

                <div class="valid-container">
                    <label >{{ `${$t('sandbar.account.validuntil')} ${_getDateTimeString(this.ticket.validuntil)}` }}</label>
                </div>
            </div>

            <div class="price-container">
                <label>{{ this.price }}</label>
            </div>

            <v-icon name="chevron-right"/>
        </div>

       <LastTicketDepiction :title="$t('sandbar.account.ticketdepiction')" :ticket="ticket" :open="showticket" @close="hideTicket"/>
    </div>
</template>

<script>
import 'vue-awesome/icons/ticket-alt'
import 'vue-awesome/icons/chevron-right'

import LastTicketDepiction from './LastTicketDepiction.vue'

export default {
    name: "PurchasedTicketDepiction",
    props: {
        ticket: Object
    },
    data() {
        return {
            showticket: false
        }
    },
    computed: {
        price: function() {
            return `${this._currencyValueToFloat(this.ticket.price)}${this._getCurrencySymbol(this.ticket.currency)}`
        }
    },
    methods: {
        hideTicket: function() {
            this.showticket = false
        },
        openTicket: function() {
            this.showticket = true
        }
    },
    components: {
        LastTicketDepiction
    }

}

</script>

<style scoped>
.ticket-container {
    width: 100%;
    height: 100%;
}

.ticket-container > .ticket-item {
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 5px 0;
    box-sizing: border-box;
}

.ticket-container > .ticket-item > .icon {
    flex: 1;
    color: #064A6C;
    margin-right: 10px;
}

.ticket-container > .ticket-item > .price-container {
    flex: 1;
    font-size: 1.2em;
    text-align: right;
    padding-right: 10px;
}

.ticket-container > .ticket-item > div {
    flex: 7;
}

.ticket-container > .ticket-item > svg {
    flex: 1;
}

.ticket-container > .ticket-item > div > .name-container {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
}


</style>