<template>
    <div v-if="!ticket.details" class="ticket-container" :class="{ 'open' : ticket.open }" @click="openDetails">
        <svg height="20" width="20" viewBox="0,0,20,20" class="details-arrow">
            <polygon points="20,0 0,0 10,10" fill="#fff"/>
        </svg>
        <div class="image-container">
            <img src="@/assets/img/ticket.png">
        </div>
        <div class="title-container">
            <label class="title">{{ this.ticket.title }}</label>
        </div>
    </div>
    <div v-else class="ticketdetails-container">
        <div class="details-indicator">
            <span>{{ $t('landing.catalog.details') }}</span>
            <v-icon name="info-circle" :title="$t('landing.catalog.detailsheader')" @click="showdetails = true"/>
            <Popup v-if="showdetails" :isopen="showdetails" @close="hideDetailsPopup" :buttonless="true" :title="this.ticket.ticket.title"
                containerstyle="height: fit-content; margin-top: calc(50vh - 100px);">
                <ul class="jss112">
                    <li id="product-detail-text-1"><div class="">4 Personen mitnehmen (6 EUR p.Pers.)</div></li>
                    <li id="product-detail-text-2"><div class="">Maximal 26 Jahre alt</div></li>
                    <li id="product-detail-text-3"><div class="">3 Kinder (bis 14 Jahre) fahren kostenlos mit</div></li>
                    <li id="product-detail-text-4"><div class="">Montag bis Freitag von 9 Uhr bis 3 Uhr</div></li>
                    <li id="product-detail-text-5"><div class="">Samstag, Sonn- und Feiertag 0 Uhr bis 3 Uhr</div></li>
                </ul>
            </Popup>
        </div>

        <TravelClassSelection v-model="travelclass" class="details-row" :radioitems="travelclasslist"/>

        <div class="details-row double">
            <DropdownInput :label="$t('landing.catalog.passengercount')" :error="$v.passengercountselectedid.$invalid" :backgroundcolor="background" :optionlist="passengercountlist" v-model="passengercountselectedid" :selected="passengercountselectedid"/>
            <TextInput type="number" :label="$t('landing.catalog.kidscount')" :error="$v.children.$invalid" :backgroundcolor="background" v-model="children" :min="0"/>
        </div>

        <div class="details-row double">
            <DateInput class="traveldate" :disabledfor="datesBeforeToday" :label="$t('landing.catalog.traveldate')" :error="$v.traveldate.$invalid" :backgroundcolor="background" v-model="traveldate"/>
            <TextInput type="number" :label="$t('landing.catalog.amount')" :error="$v.amount.$invalid" :backgroundcolor="background" v-model="amount" :min="1"/>
        </div>

        <div class="cart-section details-row" :class="{ 'loading' : isloading }">
            <button type="button" :title="$t('landing.catalog.addtocart')" @click="addToCart()" :disabled="!specificproduct" >
                <LoadingEllipsis v-if="isloading" color="#000" class="spinner"/>
                <div v-else>
                    <label>{{this.fullprice}}</label>
                    <v-icon class="cart" name="shopping-cart" scale="1.5" v-if="specificproduct" />
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import TravelClassSelection from './elements/TravelClassSelection.vue'
import DropdownInput from './elements/DropdownInput.vue'
import TextInput from './elements/TextInput.vue'
import DateInput from './elements/DateInput.vue'
import Popup from './elements/Popup.vue'

import 'vue-awesome/icons/info-circle'

import Vue from 'vue'
import axios from 'axios'
import getEnv from '@/utils/env'
import { required, minValue } from 'vuelidate/lib/validators'

export default {
    name: 'CatalogTicketDepiction',
    props: {
        ticket: {
            type: Object,
            default: () => ({})
        },
        token: {
            type: Object,
            default: () => ({
                given_name: 'GUEST',
                family_name: 'GUEST'
            })
        }
    },
    data() {
        return {
            travelclasslist: null,
            travelclass: 1,
            passengercountlist: null,
            passengercountselectedid: 1,
            traveldate: new Date(),
            children: 0,
            background: '#f9f9f9',
            amount: 1,
            isloading: true,
            specificproduct: null,
            showdetails: false
        }
    },
    validations: {
        travelclass: {
            required
        },
        passengercountselectedid: {
            required
        },
        traveldate: {
            required,
            minValue: value => new Date(value) > new Date().setDate(new Date().getDate() -1)
        },
        amount: {
            required,
            minValue: minValue(1)
        },
        children: {
            required,
            minValue: minValue(0)
        }
    },
    mounted() {
        if (this.baseproduct) {
            this.initDetailsData()
        }
    },
    computed: {
        fullprice: function() { 
            let fullpriceResult = '';
            if (this.specificproduct){
                fullpriceResult = this._currencyValueToFloat(this.specificproduct.preisInCent * this.amount)
                fullpriceResult += ' ' + this._getCurrencySymbol()
            } else {
                fullpriceResult = this.$t('landing.catalog.noprice')
            }
            return fullpriceResult
        },
        baseproduct: function() {
            return this.ticket.ticket ? this.ticket.ticket.originaldata : null
        },
        selectedtraveclass: function() {
            return this.travelclasslist[this.travelclass]
        },
        selectedpassengercount: function() {
            return this.passengercountlist[this.passengercountselectedid]
        }
    },
    methods: {
        datesBeforeToday: function(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today
        },
        getSpecificTicket: function() {
            this.isloading = true
            
            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_TARIFF_TICKETS
            url += '/' + this.baseproduct.idSortengruppe
            url += '?travelclass=' + this.selectedtraveclass.origin
            url += '&passengers=' + this.selectedpassengercount.origin

            axios.get(url)
            .then(response => {
                this.specificproduct = response.data
                this.isloading = false
            })
            .catch(err => {
                if (err.response && err.response.status === 404){
                    this.specificproduct = null
                } else {
                    Vue.$toast.error(err)                    
                }
              this.isloading = false  
            })
        },
        initDetailsData: function() {
            this.passengercountlist = []
            this.baseproduct.moeglichePersonenanzahl.forEach((entry, idx) => {
                this.passengercountlist.push({ displayid: idx, displayname: entry, origin: entry})
            })
            this.passengercountselectedid = this.passengercountlist[0].displayid

            this.travelclasslist = []
            this.baseproduct.moeglicheServiceklassen.forEach((entry, idx) => {
                this.travelclasslist.push({ displayid: idx, displayname: entry + '. ' + this.$t('landing.catalog.class'), origin: entry})
            })
            this.travelclass = this.travelclasslist[0].displayid
        },
        handlePassengerCountChange: function(event) {
            this.passengercount = event.displayid
        },
        openDetails: function() {
            this.$emit('click')
        },
        addToCart: function() {
            if (this.isloading) return

            this.$v.$touch()
            
            if (this.$v.$anyError) return

            Vue.$toast.info(this.ticket.ticket.title)
            
            let givenname = this.token.given_name
            let familyname = this.token.family_name

            let ticket = {
                ticketId: this.specificproduct.idSorte,
                ticketName: this.baseproduct.bezeichnung,
                price: this.specificproduct.preisInCent,
                priceClass: this.specificproduct.idPreisstufe, // KA_ServiceKlasse?
                prodOrgId: this.specificproduct.kA_ProdOrgID,
                departure: this.traveldate
            }

            this.$cart.addCatalogItemToCart(ticket.ticketId, ticket, givenname, familyname, this.amount)

            this.closeDetails()
        },
        closeDetails: function() {
            this.$emit('close', this.ticket.ticket, this.ticket.ticket.id)
        },
        hideDetailsPopup: function() {
            this.showdetails = false
        }
    },
    watch: {
        travelclass: function() {
            this.getSpecificTicket()
        },
        passengercountselectedid: function() {
            this.getSpecificTicket()
        }
    },
    components: {
        TravelClassSelection,
        DropdownInput,
        TextInput,
        DateInput,
        Popup
    }
}
</script>

<style scoped>
.image-container,
.image-container >>> *:hover {
    cursor: pointer;
}

*:hover.image-container:not(.open),
.open > .image-container {
    background: #00003422;
}

.ticket-container {
    width: 33%;
    position: relative;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 5px;
}

.ticket-container > .details-arrow {
    position: absolute;
    bottom: -20px;
    left: calc(50% - 10px); /* substract half of the width */
    visibility: hidden;
    filter: drop-shadow(0 3px 3px rgb(100 100 150 / 50%));
    z-index: 1;
    overflow: hidden;
}

.ticket-container.open > .details-arrow {
    visibility: visible;
}

.ticketdetails-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    background: #f9f9f9;
    color: #064A6C;
    padding: 10px 25px;
    /* padding-top: 25px; */
    font-size: 1.1em;
    box-shadow: inset 0 0 10px rgb(100 100 150 / 50%);
    overflow: hidden;
    min-height: 300px;
    /* 0px 1px 10px rgb(100 100 150 / 70%); */
}

.ticketdetails-container >>> input,
.ticketdetails-container >>> select {
    color: #064A6C;
    font-size: 1.1em;
}

.ticketdetails-container > .loading-container {
    margin-top: 100px;
}

.ticketdetails-container > .details-indicator {
    font-weight: bold;
    display: block;
    text-align: left;
    width: 100%;
    line-height: 30px;
    font-size: 1.2em;
}

.ticketdetails-container > .details-indicator > svg {
    float: right;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 6px;
    box-sizing: border-box;
}

.ticketdetails-container > .details-indicator > svg:hover {
    background: #00000022;
    cursor: pointer;
}

.ticketdetails-container > .details-row {
    margin-top: 15px;
}

.ticketdetails-container > .details-row.double {
    display: flex;
}

.ticketdetails-container > .details-row.double > * {
    box-sizing: border-box;
    flex: 1;
}

.ticketdetails-container > .details-row.double > *:nth-of-type(1) {
    margin-right: 10px;
}

.ticketdetails-container > .details-row.double > *:nth-of-type(2) {
    margin-left: 10px;
}

.ticketdetails-container > .cart-section {
    width: 100%;
}

.ticketdetails-container > .cart-section:not(.loading) > button:not(:disabled):hover {
    box-shadow: 0px 0px 10px rgb(100 100 150 / 70%);
    background: #00000022;
    cursor: pointer;
}

.ticketdetails-container > .cart-section:not(.loading) > button:not(:disabled):hover >>> * {
    cursor: pointer;
}

.ticketdetails-container > .cart-section > button {
    width: 150px;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    height: 50px;
    box-shadow: 0px 0px 5px rgb(100 100 150 / 70%);
}

.ticketdetails-container > .cart-section > button:disabled{
    background-color: #EEE;
    color: #666;
    font-size: 1em;
    text-transform: unset;
}

.ticketdetails-container > .cart-section > button > .spinner {
    width: 70px;
    margin-top: 5px;
}

.ticketdetails-container > .cart-section > button > div > .cart {
    margin-left: 10px;
}

.ticket-container > .image-container {
    overflow: hidden;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    margin: auto;
}

.ticket-container > .image-container > img {
    width: 45%;
    margin-top: 12px;
}

.ticket-container > .title-container {
    width: 90%;
    margin: auto;
    text-align: center;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ticket-container > .title-container > .title {
    font-weight: bold;
    color: #064A6C;
}

.details-container {
    position: absolute;
    width: 100%;
    background: red;
    visibility: hidden;
    height: 100px;
    bottom: 0;
}

.details-container.open {
    visibility: visible;
}

button {
    background-color: #064A6C;
    color: #fff;
    margin: 15px 0 10px 0;
    font-size: 1.2em;
    padding: 10px 20px;
    height: fit-content;
}

button:hover {
    background-color: #064A6Ccc;
    color: #fff;
}


.traveldate >>> input {
    background-color: #f9f9f9;
}

li {
    color: #000;
    font-weight: normal;
}

</style>