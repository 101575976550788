<template>
    <div class="tickets-wrapper">
        <div class="back-button" @click="goBack">
            <v-icon name="times" />
        </div>
        <div class="ticket-header">
                <label>{{ `${trip.fares[0].ticket.length} ${$t('landing.details.availabletickets')}` }}</label>
            </div>
        <div class="tickets-container">
            <div class="ticket-depiction-container">
                <TicketDepiction v-for="ticket in trip.fares[0].ticket" :key="ticket.ticketId" :ticket="ticket" class="ticket-depiction" @addtocart="addToCart"/>
            </div>
        </div>
    </div>
</template>

<script>
import TicketDepiction from './elements/TicketDepiction.vue'

import 'vue-awesome/icons/times'

export default {
    name: 'TicketBox',
    props: {
        trip: Object
    },
    computed: {
        keycloaktoken: function() {
            return this.$keycloak?.tokenParsed
        }
    },
    methods: {
        goBack: function() {
            this.$emit('back')
        },
        addToCart: function(ticket) {
            let givenname = this.keycloaktoken ? this.keycloaktoken.given_name : 'GUEST'
            let familyname = this.keycloaktoken ? this.keycloaktoken.family_name : 'GUEST'
            this.$cart.addRelationalItemToCart(ticket.ticketId, ticket, this.trip, givenname, familyname)
        }
    },
    components: {
        TicketDepiction
    }
}
</script>

<style scoped>
.tickets-wrapper {
    width: 100%;
    height: calc(100% - 100px);
    background: #fff;
}

.tickets-wrapper > .tickets-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.tickets-wrapper > .back-button:hover {
    cursor: pointer;
}

.tickets-wrapper > .back-button {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: auto;
    width: fit-content;
    color: #777;
}

.tickets-wrapper > .ticket-header {
    margin: 10px 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #064A6C;
}

.tickets-wrapper > .tickets-container >.ticket-depiction-container {
    padding-top: 10px;
    margin-bottom: 100px;
}

.tickets-wrapper > .tickets-container >.ticket-depiction-container > div {
    margin-bottom: 15px;
}
</style>