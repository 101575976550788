<template>
    <div class="transportmode-container">
        <div class="icon" >
            <div :class="[_getIconParam(this.type), { 'with-line' : this.line }]">
                <v-icon :name="_getIconParam(this.type)" :class="{ 'with-line' : this.line }" :style="this.type === 'CYCLE' ? 'width: 30px;' : ''"/>
                <div class="label-container" v-if="this.line">
                    <label>{{ this.line }}</label>
                </div>
            </div>
        </div>
        <label>{{ this.duration }} min</label>
    </div>
</template>

<script>
import 'vue-awesome/icons/walking'
import 'vue-awesome/icons/train'
import 'vue-awesome/icons/bus'
import 'vue-awesome/icons/car'
import 'vue-awesome/icons/bicycle'
import 'vue-awesome/icons/times'

export default {
    name: 'TransportModeIcon',
    props: {
        duration: Number,
        type: String,
        line: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
.transportmode-container {
    text-align: center;
}

.transportmode-container > label {
    font-size: 0.8em;
    text-align: center;
}

.transportmode-container > label:hover {
    cursor: pointer;
}

.transportmode-container > .icon {
    border-radius: 10px;
    min-width: 45px;
    height: 30px;
    overflow: hidden;
}

.transportmode-container > .icon > div {
    height: 100%;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    background: #fff;
}

.transportmode-container > .icon > div > svg {
    height: 100%;
    width: 15px;
}

.transportmode-container > .icon > div > div {
    height: 100%;
    float: right;
    text-align: center;
    margin-left: 2px;
    padding-top: 2px;
    width: calc(100% - 15px - 2px); /* minus icon width, minus margin */
}

.transportmode-container > .icon > div > div > label {
    vertical-align: middle;
    font-weight: bold;
}
.transportmode-container > .icon > div.with-line {
    text-align: left;
}

.transportmode-container > .icon > div.times {
    color: #666;
}
</style>