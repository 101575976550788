<template>
    <div class="catalog-container">
        <div class="catalog-header">
            <label>{{ $t('landing.catalog.header') }}</label>
            <div class="close-container" @click="$emit('close')">
                <v-icon class="close" name="times" scale="1.3"/>
            </div>
        </div>

        <div v-if="isloading" class="loading-container">
            <LoadingEllipsis color="#064A6C"/>
        </div>

        <div v-else class="ticketlist-container">
            <transition-group v-if="ticketlist && ticketlist.length > 0" name="ticketlist" class="ticketlist">
                <CatalogTicketDepiction :ref="ticket.id" :class="{ 'not-selected' : openticket !== null && !ticket.open }" v-for="(ticket, idx) in ticketlist" :key="ticket.id" :ticket="ticket" :token="keycloak.tokenParsed" @click="handleDetails(ticket, idx)" @close="handleDetails" />
            </transition-group>
            <div v-else class="no-tickets">
                <label>{{ $t('landing.catalog.notickets') }}</label>
                <v-icon name="redo-alt" @click="getTicketGroups"/>
            </div>
        </div>
    </div>
</template>

<script>
import CatalogTicketDepiction from './CatalogTicketDepiction.vue'
import LoadingEllipsis from './elements/LoadingEllipsis.vue'

import 'vue-awesome/icons/times'
import 'vue-awesome/icons/redo-alt'

import Vue from 'vue'
import axios from 'axios'
import getEnv from '@/utils/env'

export default {
    name: 'TicketCatalog',
    data() {
        return {
            openticket: null,
            isloading: false,
            ticketlist: null
        }
    },
    computed: {
        keycloak: function(){
            return this.$keycloak
        }
    },
    mounted() {
        this.getTicketGroups()
    },
    methods: {
        getTicketGroups: function() {
            this.isloading = true
            
            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_TARIFF_TICKETGROUPS

            axios.get(url)
            .then(response => {
                const ticketgroups = response.data

                this.ticketlist = []

                ticketgroups.forEach((group, idx) => {
                    this.ticketlist.push({
                        id: idx,
                        title: group.bezeichnung,
                        info: group.bezeichnung,
                        open: false,
                        loadingdetails: true,
                        originaldata: group
                    })
                })

                this.isloading = false
            })
            .catch(err => {
                this.isloading = false
                Vue.$toast.error(err)
            })
        },
        handleDetails: async function(ticket, idx) {
            // 1. decided wether to open or close the details by looking up if the list has a details ticket
            let removeindex
            let activeindex
            this.ticketlist.forEach((entry, entryidx) => {
                // close the ticket not the details
                if (entry.open && !entry.details) {
                    entry.open = false
                    this.openticket = null
                    activeindex = entry.id
                }
                // the ticket which is open always comes before the details ticket
                // so if we find an open ticket, there will be a details entry afterwards
                if (entry.details) {
                    removeindex = entryidx
                }
            })
            
            // remove the details ticket and DONT leave the function -> if another ticket
            // was selected we want to open this instead
            if (removeindex) {
                this.ticketlist.splice(removeindex, 1)
                // wait for the leaving animation to finish
                await new Promise(r => setTimeout(r, 300));
            }

            // if the same item thas is already open was clicked
            // we do not need to open it again -> thus leave the function
            if (activeindex === idx) {
                return
            }

            // 2. if we have open the details
            // we start by getting the next row by determining the next idx thats a multiple of 3
            
            // 0 based index to 1 based row calculator
            let idthree = idx + 1
            let nextrowid = 0

            if(idthree > 0) {
                nextrowid = Math.ceil(idthree/3.0) * 3 + 1;
            } else if (idthree < 0) {
                nextrowid =  Math.floor(idthree/3.0) * 3 + 1;
            }

            // 3. add a details ticket at the given idx
            // prepare the ticket
            ticket.open = true
            this.openticket = ticket.id

            let detailsticket = {}
            detailsticket.open = true
            detailsticket.ticket = ticket
            detailsticket.details = true
            detailsticket.info = ticket.info
            detailsticket.id = ticket.id.toString() + '-details'

            // return to 0 based idx for the list
            // add ticket
            let detailsidx = nextrowid - 1
            this.ticketlist.splice(detailsidx, 0, detailsticket)

            // We DO NOT need to account for "unfilled" rows (e.g. only one ticket in the last row)
            // since the details view is 100% width which will always be in a new line
        },
        sleep: function(milliseconds) {
            var start = new Date().getTime();
            for (var i = 0; i < 1e7; i++) {
                if ((new Date().getTime() - start) > milliseconds){
                    break;
                }
            }
        }
    },
    components: {
        CatalogTicketDepiction,
        LoadingEllipsis
    }
}
</script>

<style scoped>
.catalog-container {
    width: 100%;
    height: 100%;
    background: #fff;
}

.catalog-container > .catalog-header {
    position: relative;
    background: #064A6C;
    height: 50px;
    width: 100%;
    line-height: 50px;
    color: #fff;
    font-size: 1.4em;
}

.catalog-container > .catalog-header > .close-container {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    line-height: 35px;
    border-radius: 50%;
}

.catalog-container > .catalog-header > .close-container:hover {
    cursor: pointer;
    background: #ffffff22;
}

.catalog-container > .catalog-header > .close-container > svg {
    top: 0;
}

.catalog-container > .loading-container {
    margin-top: 50px;
}

.catalog-container > .ticketlist-container {
    /* full height minus the ticket head, minus the searchbox*/
    height: calc(100% - 50px - 250px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 10px;
    box-sizing: border-box;
}

.catalog-container > .ticketlist-container > .ticketlist {
    display: flex;
    min-width: 400px;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
    box-sizing: border-box;
}

.catalog-container > .ticketlist-container > .no-tickets {
    max-width: 225px;
    font-size: 1.3em;
    margin: 20px auto;
    color: #064A6C;
    line-height: 30px;
}

.catalog-container > .ticketlist-container > .no-tickets > label {
    display: block;
}

.catalog-container > .ticketlist-container > .no-tickets > svg {
    margin-top: 20px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 5px;
}

.catalog-container > .ticketlist-container > .no-tickets > svg:hover {
    background: #00000022;
    cursor: pointer;
}

.not-selected {
    opacity: .3;
    transition: .3s;
}

/* list aninmations */
.ticketlist-enter-active,
.ticketlist-leave-active {
    transition: all .3s;
    transform-origin: 50% 0px;
}

.ticketlist-enter {
    transform: scaleY(0);
    max-height: 0px;
}

.ticketlist-leave-to {
    transform: scaleY(0);
    max-height: 0;
    min-height: 0px;
    padding: 0;
    margin: 0;
    color: transparent;
}
</style>