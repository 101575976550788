<template>
    <div class="page-container">
        <div class="headline-container">
            <label>{{ $t('sandbar.settings') }}</label>
            <v-icon class="loading-spinner" name="spinner" v-show="isloading"/>
        </div>
        <div class="profiles-container">
            <div class="profile-wrapper" v-for="profile in profiles" :key="profile.einstellungsId">
                <div class="profile" :class="{ 'active' : isActiveProfile(profile) }" @click="!isloading ? changeProfile(profile) : ''">
                    <v-icon class="profile-icon" name="map-marked"/>
                    <label class="profile-label">{{ profile.einstellungsName }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import getEnv from '@/utils/env'

import 'vue-awesome/icons/map-marked'
import 'vue-awesome/icons/spinner'

export default {
    name: 'ProfileSwitcherPage',
    data() {
        return {
            profiles: [],
            userprofiles: [],
            availableloading: true,
            userloading: true,
            user: undefined,
            userparam: undefined
        }
    },
    mounted() {

        if (this.$store.state.keycloak.npsuserid) {
            this.user = this.$store.state.keycloak.npsuserid
            this.init()
        } else {
            let useridurl = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_NPS_USER_IDENTIFY_KEYCLOAK_USER
            useridurl += `/${this.$route.query.user}`

            axios.get(useridurl)
            .then(response => {
                this.user = response.data.userid
                this.init()
            })
            .catch(err => {
                console.error(err)
            })
        }
    },
    computed: {
        isloading: function() {
            return this.availableloading || this.userloading
        }
    },
    methods: {
        init: function() {
            this.getAvailableProfiles()
            this.getUserProfiles(this.user)
        },
        getAvailableProfiles: function(){
            this.availableloading = true
            
            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_NPS_SETTINGS

            axios.get(url)
            .then(res => {
                this.profiles = res.data
                this.availableloading = false
            })
            .catch(err => {
                this.availableloading = false
                console.log(err)
            })
        },
        getUserProfiles: function(user) {
            this.userloading = true
            
            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_NPS_USER_SETTINGS
            url = url.replace('<userid>', user)

            axios.get(url)
            .then(res => {
                this.userprofiles = res.data
                this.userloading = false
            })
            .catch(err => {
                this.userloading = false
                console.log(err)
            })
        },
        changeProfile: function(profile) {
            if (this.isActiveProfile(profile)) {
                this.removeProfile(profile)
            } else {
                this.addProfile(profile)
            }
        },
        addProfile: function(profile) {
            this.userloading = true

            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_NPS_USER_SETTINGS
            url = url.replace('<userid>', this.user)

            axios.post(url, profile)
            .then(() => {
                this.init()
            })
            .catch(err => {
                console.log(err)
                this.userloading = false
            })
        },
        removeProfile: function(profile) {
            this.userloading = true

            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_NPS_USER_SETTINGS
            url = url.replace('<userid>', this.user)

            axios.delete(url, { data: profile })
            .then(() => {
                this.init()
            })
            .catch(err => {
                console.log(err)
                this.userloading = false
            })
        },
        isActiveProfile: function(profile) {
            let result = false
            if (this.userprofiles) {
                this.userprofiles.forEach(element => {
                    if (element.einstellungsId === profile.einstellungsId) result = true
                })
            }
            return result
        }
    }
}
</script>

<style scoped>
.page-container {
    height: 100%;
    background: #fff;
    margin: 0 auto;
    overflow: auto;
    padding: 20px;
    min-width: 160px;
}

.headline-container {
    font-weight: bold;
    position: relative;
    text-align: center;
}

.profiles-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
    text-align: left;
}

.profile-wrapper {
    width: 50%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile {
    width: 83%;
    background: #fff;
    box-shadow: 0px 1px 5px rgb(100 100 150 / 50%);
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
}

.profile:hover,
.profile:hover >>> * {
    cursor: pointer;
    background: #eee;
}

.profile.active,
.profile.active >>> * {
    color: #fff;
    background: #064B6C;
}

.profile.active:hover,
.profile.active:hover >>> * {
    background: #165B7C;
}

.profile-label {
    margin-left: 15px;
}

.loading-spinner {
    position: absolute;
    right: 0px;
    top: calc(50% - 8px);
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>