<template>
    <div class="resultcard-container" @click="clicked">
        <div class="card-content">
            <div class="transport-depiction">
                <div v-for="(leg, idx) in trip.legs" :key="JSON.stringify(leg)" class="type-container" :class="{ 'spacer' : idx !== 0}">
                    <TransportModeIcon :duration="leg.duration" :type="leg.type" :line="leg.line ? leg.line.toString() : null"/>
                </div>
            </div>
            <div class="time-depiction">
                <label><span>{{ this.duration }}</span> min</label>
            </div>
        </div>

        <div class="card-footer">
            <label v-if="withstarttime" class="left">
                <span>{{ $t('landing.result.at') }} {{ this.tripstarttime }}</span> {{ $t('landing.result.from') }} {{ this.trip.start.locationname }}
            </label>
            <label v-else class="left"><span>{{ $t('landing.result.startnow') }}</span></label>
            <label class="right">{{ $t('landing.result.arrival') }} <span>{{ this.tripendtime }}</span></label>
        </div>

        <div class="button-container" v-if="details || ticketsonly" v-show="showdetails" @click="detailsClicked">
            <input type="button" :value="ticketsonly ? $t('landing.result.tickets') : $t('landing.result.details')">
            <v-icon name="chevron-right"/>
        </div>

        <div class="delimiter"></div>
    </div>
</template>

<script>
import TransportModeIcon from './elements/TransportModeIcon.vue'

import 'vue-awesome/icons/chevron-right'

export default {
    name: 'ResultCard',
    props: {
        trip: Object,
        details: {
            type: Boolean,
            default: false
        },
        withstarttime: Boolean,
        ticketsonly: Boolean,
        tolocationoverride: {
            type: String,
            default: null
        },
        showdetails: Boolean
    },
    computed: {
        tolocation: function() {
            if (this.tolocationoverride && this.tolocationoverride.trim() !== '') {
                return this.tolocationoverride
            } else {
                return this.trip.end.locationname
            }
        },
        tripstarttime: function() {
            let datetime = new Date(this.trip.start.time)
            return this._leadingZero(datetime.getHours()) + ':' + this._leadingZero(datetime.getMinutes())
        },
        tripendtime: function() {
            let datetime
            if (this.trip.end.time) {
                datetime = new Date(this.trip.end.time)
            } else {
                datetime = new Date()
                datetime.setMinutes(datetime.getMinutes() + this.duration)
            }

            return this._leadingZero(datetime.getHours()) + ':' + this._leadingZero(datetime.getMinutes())
        },
        duration: function() {
            if (this.trip.duration) {
                return this.trip.duration
            }

            let duration = 0
            this.trip.legs.forEach(entry => {
                duration += entry.duration
            })
            return duration
        }
    },
    methods: {
        clicked: function() {
            this.$emit('click', this.trip)
        },
        detailsClicked: function() {
            let event = 'detailsClicked'
            if (this.ticketsonly) {
                event = 'ticketsClicked'
            }
            this.$emit(event, this.trip)
        }
    },
    components: {
        TransportModeIcon
    }
}
</script>

<style scoped>
.resultcard-container {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 10px 20px 0 20px;
    text-align: left;
}

.resultcard-container:hover {
    background-color: rgba(255, 255, 255, .2);
    cursor: pointer;
}

.resultcard-container > .card-content {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
}

.resultcard-container > .button-container {
    width: fit-content;
    text-align: right;
    margin: 10px 0 10px auto;
}

.resultcard-container > .button-container:hover > input,
.resultcard-container > .button-container:hover > svg {
    color: #064A6C;
}

.resultcard-container > .button-container > input:hover {
    background: inherit;
}

.resultcard-container > .button-container > svg {
    color: #fff;
}

.resultcard-container > .button-container > input {
    font-size: 1.2em;
    min-width: 70px;
}

.resultcard-container > .card-content > .transport-depiction {
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: 10px;
}

.resultcard-container > .card-content > .transport-depiction > .type-container {
    margin: 0 10px;
    position: relative;
    color: #fff;
}

.resultcard-container > .card-content > .transport-depiction > .type-container:nth-child(1) {
    margin-left: 0px;
}

.resultcard-container > .card-content > .transport-depiction > .type-container.spacer::before {
    content: '\2022';
    position: absolute;
    font-size: 40px;
    top: -10px;
    left: -15px;
}

.resultcard-container > .card-content > .transport-depiction {
    flex: 9;
    display: flex;
    align-items: center;
}

.resultcard-container > .card-content > .time-depiction {
    font-size: 1.5em;
    text-align: right;
    flex: 1;
    color: #fff
}

.resultcard-container > .card-content > .time-depiction > label:hover {
    cursor: pointer;
}

.resultcard-container > .card-header,
.resultcard-container > .card-footer {
    color: #fff;
}

.resultcard-container > .card-header {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.resultcard-container > .card-footer {
    display: flex;
    align-content: space-between;
    margin-bottom: 5px;
}

.resultcard-container > .card-footer > label:hover {
    cursor: pointer;
}

.resultcard-container > .card-footer > .left {
    width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.resultcard-container > .card-footer > .left > span {
    margin-right: 5px;
}

.resultcard-container > .card-footer > .right {
    text-align: right;
    width: 30%;
}

.resultcard-container > .card-footer > label > span,
.resultcard-container > .card-header > label > span,
.resultcard-container > .card-content > .time-depiction > label > span {
    font-weight: bold;
}

.resultcard-container > .card-header > label > span {
    font-weight: bold;
    margin-left: 5px;
}

.resultcard-container > .delimiter {
    border: 1px solid #fff;
}
</style>