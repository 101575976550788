<template>
    <div id="app">
        <router-view />
        <Footer />
    </div>
</template>

<script>
import Footer from './components/systems/elements/Footer.vue'

export default {
    name: 'App',
    components: {
        Footer
    }
}
</script>

<style>
@import './assets/css/application.css';
@import './assets/css/toasts.css';
</style>
