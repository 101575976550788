<template>
    <div class="popup-background">
        <div class="popup-container" v-click-outside="close" :style="containerstyle">
            <div class="header-container">
                <label class="header">{{ this.title }}</label>
                <v-icon name="times" scale="1" class="close-button" @click="close(false)"/>
            </div>

            <div class="content-container">
                <slot>{{ $t('general.are-you-sure')}}</slot>
            </div>

            <div class="popup-button-container" v-if="!buttonless">
                <input :disabled="isloading" type="button" class="button" @click="close(true)" :value="submitlabel"/>
                <input :disabled="isloading" type="button" class="button" @click="close(false)" :value="cancellabel"/>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/times'

export default {
    name: 'Popup',
    props: {
        title: String,
        isopen: {
            type: Boolean,
            default: false
        },
        submitlabel: {
            type: String,
            default: 'Yes'
        },
        cancellabel: {
            type: String,
            default: 'No'
        },
        isloading: {
            type: Boolean,
            default: false
        },
        containerstyle: String,
        buttonless: Boolean
    },
    mounted() {
        document.body.classList.toggle('noscroll', true)
    },
    methods: {
        close: function(success) {
            // prevent closing the dialog while something is loading
            if (!this.isloading) {
                document.body.classList.toggle('noscroll', false)
                this.$emit('close', success)
            }
        }
    }
}
</script>

<style scoped>
.popup-background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: calc(100% - 30px);
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px;

    width: 100%;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    box-sizing: border-box;
}

.popup-container {
    background: #fff;
    position: relative;
    box-sizing: border-box;
    height: fit-content;
    width: 100%;
    max-width: 450px;
    padding: 20px;
    border-radius: 5px;
    margin: auto;
}

.header-container {
    font-weight: bold;
    font-size: 1.2em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-container {
    margin-top: 20px;
    overflow-y: auto;
    box-sizing: border-box;
}

.popup-button-container {
    width: 100%;
    text-align: left;
    margin-top: 20px;
}

.popup-button-container > .button {
    color: #064A6C;
}

.popup-button-container > .button:hover {
    background-color: #064A6C22;
}

.close-button {
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 50%;
    color: #777;
}

.close-button:hover {
    background-color: #77777733;
    cursor: pointer;
}

@media(max-width: 450px) {
    .popup-container {
        max-width: 90%;
    }
}
</style>